import React from 'react';
import { MaxHeight } from '../Basic/MaxHeight';
import { Image, Flex } from 'antd';
import { BarLoader } from 'react-spinners';
import Center from '../Basic/Center';

const DefaultLoading = ({ loadingToken }) => {
    return (
        <MaxHeight>
            {/* <FormHeader /> */}
            <Center>
                <Flex vertical gap={10}>
                    <Image
                        preview={false}
                        src={'/logo.png'}
                        // height={30}
                        width={'10vw'}
                    />
                    <div
                        style={{
                            opacity: loadingToken ? 1 : 0,
                            width: '10vw',
                            borderRadius: '5px',
                            overflow: 'hidden',
                        }}
                    >
                        <BarLoader color={'rgb(30, 47, 105)'} width={'10vw'} />
                    </div>
                </Flex>
            </Center>
        </MaxHeight>
    );
};

export default DefaultLoading;

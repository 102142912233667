import React, { useEffect } from "react";
import { Question } from "../Components/Form/Question.jsx";
import { Flex, Form, Space, Typography, Grid } from "antd";
import { useQuestionContext } from "../Contexts/QuestionContext.jsx";
import { motion } from "framer-motion";
import { FormButton } from "../Components/Form/FormButton.jsx";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
    QUESTION_INDEX_ANIMATION_DURATION,
    TEXT_FADE_STAGGER_DURATION,
    questionIndexAnimation,
} from "../Constants/animations.js";
import {
    getQuestionIndexStyle,
    indexIconMarginLeft,
    questionTitleStyle,
} from "../Constants/styles.js";
import { useFormContext } from "../Contexts/FormContext.jsx";

export const QuestionView = () => {
    const {
        next,
        currentQuestionIndex,
        setFormInstance,
        onValuesChanged,
        answers,
        isSubQuestion,
        showNextButton,
        setShowNextButton,
        formTheme,
    } = useFormContext();
    const constTheme = formTheme;

    const { currentQuestion, initial } = useQuestionContext();
    // console.log({ currentQuestion });
    const [form] = Form.useForm();

    useEffect(() => {
        setFormInstance(form);
        form.setFieldsValue(answers);
    }, [form, setFormInstance, answers]);

    useEffect(() => {
        if (!initial) {
            setShowNextButton(true);
        }
    }, [currentQuestion]);

    // useEffect(() => {
    //   const subQuestions = currentQuestion?.properties?.fields || [];
    //   const hasSubQuestions = subQuestions && subQuestions.length > 0;
    //   console.log("CURRENT QUESTION INDEX CHANGED", {currentQuestionIndex, hasSubQuestions});
    //   setIsSubQuestion([hasSubQuestions, 0, subQuestions.length]);
    // }, [currentQuestionIndex]);

    // const onFinish = (values) => {
    //   console.log("Received values of form: ", values);
    //   next();
    // };

    // const onFinish = () => {
    //   form
    //     .validateFields()
    //     .then((values) => {
    //       console.log({ values });
    //       next();
    //     })
    //     .catch((err) => {
    //       console.log({ err });
    //       // setError(err);
    //     });
    // };

    // const errors = form.getFieldsError();
    // console.log({ errors });

    const onValuesChange = (changedValues, allValues) => {
        onValuesChanged(changedValues);
    };

    // return (
    //   <QuestionViewFormat
    //     index={currentQuestionIndex}
    //     secondary_color={styles.secondary_color || styles.primary_color}
    //   />
    // );

    // console.log(constTheme);
    const textColor = constTheme?.text_color;

    return (
        <QuestionViewFormat
            textColor={constTheme?.text_color}
            index={currentQuestion?.question_number ?? currentQuestionIndex}
            content={
                <Form
                    onFinish={next}
                    // onSubmitCapture={next}
                    form={form}
                    onValuesChange={onValuesChange}
                    disabled={!showNextButton}
                    // style={{ backgroundColor: "red" }}
                >
                    <QuestionViewContentFormat
                        question={
                            <>
                                {isSubQuestion[0] ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            // gap: "1rem",
                                        }}
                                    >
                                        <Typography.Text
                                            style={{
                                                ...questionTitleStyle(),
                                                color: constTheme?.text_color,
                                            }}
                                        >
                                            {/* <FadeAnimatedText
                        disableAnimation={!initial}
                        textArray={currentQuestion?.title?.split()}
                        delay={QUESTION_INDEX_ANIMATION_DURATION * 2}
                      /> */}
                                            {currentQuestion?.title}
                                        </Typography.Text>

                                        <Flex vertical gap="small">
                                            {currentQuestion?.properties?.fields?.map(
                                                (field, index) => {
                                                    // console.log({ field });
                                                    return (
                                                        <Question
                                                            key={field.id}
                                                            field={field}
                                                            mainField={
                                                                currentQuestion.type
                                                            }
                                                            delay={
                                                                QUESTION_INDEX_ANIMATION_DURATION +
                                                                currentQuestion?.title?.split()
                                                                    .length *
                                                                    TEXT_FADE_STAGGER_DURATION *
                                                                    0.4 +
                                                                1 * index
                                                            }
                                                            index={index}
                                                            isLastQuestion={
                                                                index ===
                                                                currentQuestion
                                                                    ?.properties
                                                                    ?.fields
                                                                    ?.length -
                                                                    1
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                        </Flex>
                                        <button
                                            style={{
                                                display: "none",
                                            }}
                                            htmlType="submit"
                                        />
                                    </div>
                                ) : (
                                    <Question field={currentQuestion} />
                                )}
                            </>
                        }
                        bottomButton={<FormButton>Next</FormButton>}
                    />
                </Form>
            }
        />
    );
};

export const QuestionViewFormat = ({ index, textColor, content }) => {
    return (
        <Flex gap={"small"}>
            <Flex style={{ minWidth: "8px", justifyContent: "flex-end" }}>
                <motion.div {...questionIndexAnimation}>
                    <Typography.Text style={getQuestionIndexStyle(textColor)}>
                        {/*      {index} */}
                        <ArrowRightOutlined
                            style={{
                                marginLeft: indexIconMarginLeft,
                                display: "none",
                            }}
                        />
                    </Typography.Text>
                </motion.div>
            </Flex>
            {content}
        </Flex>
    );
};

export const QuestionViewContentFormat = ({
    bottomButton,
    question,
    editor = false,
    isAttachment = false,
}) => {
    const { useBreakpoint } = Grid;
    const { initial } = useQuestionContext();
    // const { currentQuestionIndex } = useFormContext();

    // const [sNB, setSNB] = useState(false);

    const { xs, sm, md, lg, xl } = useBreakpoint();
    const { showNextButton } = useFormContext();

    // console.log({ initial, showNextButton });
    const dynamicWidth = editor && isAttachment ? "67vw" : "40vw";

    return (
        <motion.div
            initial={
                {
                    // translateY: 50
                }
            }
            animate={{
                // translateY: 0,
                transition: {
                    staggerChildren: 2,
                },
            }}
            style={{
                width: xl
                    ? dynamicWidth
                    : lg
                    ? "60vw"
                    : md
                    ? "70vw"
                    : xs
                    ? "75vw"
                    : "85vw",
                //paddingRight: "50px",
                // backgroundColor: 'green',

                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "flex-start",
                cursor: "default",

                // gap: "1rem",
            }}
        >
            {question}
            <Space size={"large"} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: showNextButton || !initial ? 1 : 0 }}
            >
                {bottomButton}
            </motion.div>
        </motion.div>
    );
};

export function convertBackToOldFormat(newObject, newThemeObject) {
    // Check if welcomeScreen is an empty object
    const isWelcomeScreenEmpty = newObject?.welcome_screens?.[0];
    const isEndScreenEmpty = newObject?.thankyou_screens?.[0];

    return {
        id: newObject?.id || newObject?._id,
        name: newObject?.title || newObject?.name,
        workspace: {
            _id: newObject?.workspace?._id || newObject?.workspace?.id,
        },
        type: newObject?.type,
        owner_id: newObject?.owner_id,
        url_id: newObject?.url_id,
        created_at: newObject?.created_at,
        last_updated_at: newObject?.last_updated_at,
        theme: {
            primary_color: newThemeObject?.primary_color,
            secondary_color: newThemeObject?.secondary_color,
            text_color: newThemeObject?.text_color,
            background_color: newThemeObject?.background_color,
            answer_text_color: newThemeObject?.answer_text_color,
            font_family: newThemeObject?.font_family,
            font: newThemeObject?.font,
            font_weight: newThemeObject?.font_weight,

            name: newThemeObject?.name,
            button_color: newThemeObject?.button_color,
            button_size: newThemeObject?.button_size,
            button_text_color: newThemeObject?.button_text_color,
            is_bold: newThemeObject?.is_bold,
            visibility: newThemeObject?.visibility,
            logo: {
                _id: newThemeObject?.logo?._id,
                type: newThemeObject?.logo?.type,
                link: newThemeObject?.link,
                properties: {
                    description: newThemeObject?.properties?.description,
                    focal_point: {
                        x: newThemeObject?.properties?.focal_point?.x,
                        y: newThemeObject?.properties?.focal_point?.y,
                    },
                },
            },
            text_size: newThemeObject?.text_size,
            rounded_corners: newThemeObject?.rounded_corners,
            _id: newThemeObject?._id || newThemeObject?.id,
            created_at: newThemeObject?.created_at,
            updated_at: newThemeObject?.updated_at,
            owner_id: newThemeObject?.owner_id,
        },
        primary_color:
            newThemeObject?.primary_color || newObject?.primary_color,
        text_size: newThemeObject?.text_size || newObject?.text_size,
        secondary_color:
            newThemeObject?.secondary_color || newObject?.secondary_color,
        welcomeScreen: isWelcomeScreenEmpty
            ? {
                  title:
                      newObject?.welcomeScreen?.title ||
                      newObject?.welcome_screens?.[0]?.title,
                  type:
                      newObject?.welcomeScreen?.type ||
                      newObject?.welcome_screens?.[0]?.type,

                  description:
                      newObject?.welcomeScreen?.description ||
                      newObject?.welcome_screens?.[0]?.properties.description,

                  button_text:
                      newObject?.welcomeScreen?.button_text ||
                      newObject?.welcome_screens?.[0]?.properties?.button_text,
                  layout: {
                      attachment: {
                          href:
                              newObject?.welcomeScreen?.layout?.attachment
                                  ?.href ||
                              newObject?.welcome_screens?.[0]?.layout
                                  ?.attachment?.link ||
                              null,
                      },

                      placement:
                          newObject?.welcomeScreen?.layout?.placement ||
                          newObject?.welcome_screens?.[0]?.layout?.placement ||
                          'right',
                  },
              }
            : {},
        endScreen: isEndScreenEmpty
            ? {
                  title:
                      newObject?.endScreen?.title ||
                      newObject?.thankyou_screens?.[0]?.title,
                  type:
                      newObject?.endScreen?.type ||
                      newObject?.thankyou_screens?.[0]?.type,
                  description:
                      newObject?.endScreen?.description ||
                      newObject?.thankyou_screens?.[0]?.properties?.description,
              redirectUrl:
                newObject?.endScreen?.redirectUrl ||
                newObject?.thankyou_screens?.[0]?.properties?.redirect_url,
              }
            : {},
        fields: newObject?.fields,
        settings: newObject?.settings,
        hidden: newObject?.hidden,
        links: newObject?.links,
    };
}

/* converting form object */
export function convertFormObject(oldObject) {
    // Check if welcomeScreen is an empty object
    const isWelcomeScreenEmpty =
        oldObject?.welcomeScreen &&
        Object.keys(oldObject.welcomeScreen).length === 0;

    const isEndScreenEmpty =
        oldObject?.endScreen && Object.keys(oldObject.endScreen).length === 0;

    return {
        id: oldObject?.id,
        title: oldObject?.name,
        type: 'quiz',
        url_id: oldObject?.url_id || '',
        owner_id: '',
        created_at: '',
        last_updated_at: '',
        /*   theme: {
      _id: oldObject?.theme?._id || oldObject?.theme?.id,
    }, */
        fields: oldObject?.fields,
        welcome_screens: !isWelcomeScreenEmpty
            ? [
                  {
                      id: '',
                      title: oldObject?.welcomeScreen?.title,
                      type: oldObject?.welcomeScreen?.type,
                      properties: {
                          description: oldObject?.welcomeScreen?.description,
                          redirect_url: 'https://www.google.com',
                          show_button: true,
                          share_icons: true,
                          button_mode: 'redirect',
                          button_text: oldObject?.welcomeScreen?.button_text,
                          text_align: 'center',
                      },
                      layout: {
                          type: 'float',
                          attachment: {
                              _id: '',
                              link: oldObject?.welcomeScreen?.layout?.attachment
                                  ?.href,
                              type: 'image',
                              placement:
                                  oldObject?.welcomeScreen?.layout?.placement ||
                                  '',
                              properties: {
                                  description: '',
                                  focal_point: {
                                      x: 0.5,
                                      y: 0.5,
                                  },
                              },
                          },
                          placement:
                              oldObject?.welcomeScreen?.layout?.placement,
                          viewport_overrides: {
                              large: {
                                  type: 'float',
                                  placement: 'top',
                              },
                          },
                      },
                      attachment: {
                          _id: '',
                          type: 'image',
                          link: '',
                          properties: {
                              description: '',
                              focal_point: {
                                  x: 0,
                                  y: 0,
                              },
                          },
                          placement: 'top',
                      },
                  },
              ]
            : [],
        thankyou_screens: !isEndScreenEmpty
            ? [
                  {
                      id: '',
                      title: oldObject?.endScreen?.title,
                      type: oldObject?.endScreen?.type,
                      properties: {
                          description: oldObject?.endScreen?.description,
                          redirect_url: oldObject?.endScreen?.redirectUrl,
                          show_button: true,
                          share_icons: true,
                          button_mode: 'redirect',
                          button_text: 'Continue',
                          text_align: 'center',
                      },
                      attachment: null,
                      layout: null,
                  },
              ]
            : [],
        settings: {
            language: 'en',
            progress_bar: 'percentage',
            meta: {
                title: 'Bootcamp Application Form',
                allow_indexing: true,
                description: '',
                image: {
                    link: oldObject?.settings?.meta?.image?.link,
                },
            },
            hide_navigation: false,
            is_public: true,
            is_trial: false,
            show_progress_bar: oldObject?.settings?.show_progress_bar,
            are_uploads_public: false,
            show_time_to_complete: false,
            show_number_of_submissions: true,
            show_cookie_consent: false,
            show_question_number: true,
            show_key_hint_on_choices: true,
            autosave_progress: true,
            free_form_navigation: false,
            accept_responses: oldObject?.settings?.accept_responses,
        },
        hidden: [],
        links: {
            display: '',
            responses: '',
            custom: '',
        },
        is_ai_form: false,
        ai_form: null,
    };
}

export function convertTOThemeObject(newThemeObject) {
    return {
        primary_color: newThemeObject?.primary_color,
        secondary_color: newThemeObject?.secondary_color,
        text_color: newThemeObject?.text_color,
        background_color: newThemeObject?.background_color,
        answer_text_color: newThemeObject?.answer_text_color,
        font_family: newThemeObject?.font_family,
        font: newThemeObject?.font,
        font_weight: newThemeObject?.font_weight,

        name: newThemeObject?.name,
        button_color: newThemeObject?.button_color,
        button_size: newThemeObject?.button_size,
        button_text_color: newThemeObject?.button_text_color,
        is_bold: newThemeObject?.is_bold,
        visibility: newThemeObject?.visibility,
        logo: {
            _id: newThemeObject?.logo?._id,
            type: newThemeObject?.logo?.type || 'image',
            link: newThemeObject?.link || '',
            placement: newThemeObject?.placement || 'top',
            properties: {
                description: newThemeObject?.properties?.description || '',
                focal_point: {
                    x: newThemeObject?.properties?.focal_point?.x || 0.5,
                    y: newThemeObject?.properties?.focal_point?.y || 0.5,
                },
            },
        },
        text_size: newThemeObject?.text_size,
        rounded_corners: newThemeObject?.rounded_corners,
        _id: newThemeObject?._id || newThemeObject?.id,
        created_at: newThemeObject?.created_at,
        updated_at: newThemeObject?.updated_at,
        owner_id: newThemeObject?.owner_id,
    };
}

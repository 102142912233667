import {
    CheckSquareOutlined,
    FileTextOutlined,
    GlobalOutlined,
    CreditCardOutlined,
    DollarOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import {
    PencilLine,
    ListOrdered,
    Mail,
    ChevronDown,
    Phone,
    SquarePen,
    LayoutList,
    Globe,
    FileText,
    Info,
    FilePlus,
} from "lucide-react";

export const QUESTION_ICONS = {
    multiple_choice: <ListOrdered size={12} />,
    short_text: <PencilLine size={12} />,
    email: <Mail size={12} />,
    dropdown: <ChevronDown size={12} />,
    phone_number: <Phone size={12} />,
    section: <LayoutList size={12} />,
    contact_info: <Info size={12} />,
    checkbox: <CheckSquareOutlined />,
    multiple_field: <FileTextOutlined />,
    legal: <FileText size={12} />,
    country: <Globe size={12} />,
    ai_statement: <SquarePen size={12} />,
    statement: <LayoutList size={12} />,
    video: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.3332 4.66667L9.83317 7L13.3332 9.33333V4.66667Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.6665 3.5H2.83317C2.18884 3.5 1.6665 4.02233 1.6665 4.66667V9.33333C1.6665 9.97767 2.18884 10.5 2.83317 10.5H8.6665C9.31084 10.5 9.83317 9.97767 9.83317 9.33333V4.66667C9.83317 4.02233 9.31084 3.5 8.6665 3.5Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
    AI_graph: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_788_573)">
                <path
                    d="M1.94434 1.65234V12.1523H12.4443M11.2777 5.15234L8.361 8.06901L6.02767 5.73568L4.27767 7.48568"
                    stroke="#091E42"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_788_573">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    AI_image: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.25 7.0013V11.0846C12.25 11.3941 12.1271 11.6908 11.9083 11.9096C11.6895 12.1284 11.3928 12.2513 11.0833 12.2513H2.91667C2.60725 12.2513 2.3105 12.1284 2.09171 11.9096C1.87292 11.6908 1.75 11.3941 1.75 11.0846V2.91797C1.75 2.60855 1.87292 2.3118 2.09171 2.09301C2.3105 1.87422 2.60725 1.7513 2.91667 1.7513H7M9.33333 2.91797H12.8333M11.0833 1.16797V4.66797M12.25 8.7513L10.4498 6.95114C10.2311 6.73242 9.93436 6.60955 9.625 6.60955C9.31564 6.60955 9.01895 6.73242 8.80017 6.95114L3.5 12.2513M6.41667 5.2513C6.41667 5.89563 5.89433 6.41797 5.25 6.41797C4.60567 6.41797 4.08333 5.89563 4.08333 5.2513C4.08333 4.60697 4.60567 4.08464 5.25 4.08464C5.89433 4.08464 6.41667 4.60697 6.41667 5.2513Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
    file_upload: <FilePlus size={12} />,
    yes_no: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.49984 8.7513L8.6665 9.91797L10.9998 7.58464M2.83317 9.33463C2.1915 9.33463 1.6665 8.80964 1.6665 8.16797V2.33464C1.6665 1.69297 2.1915 1.16797 2.83317 1.16797H8.6665C9.30817 1.16797 9.83317 1.69297 9.83317 2.33464M6.33317 4.66797H12.1665C12.8108 4.66797 13.3332 5.1903 13.3332 5.83464V11.668C13.3332 12.3123 12.8108 12.8346 12.1665 12.8346H6.33317C5.68884 12.8346 5.1665 12.3123 5.1665 11.668V5.83464C5.1665 5.1903 5.68884 4.66797 6.33317 4.66797Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.19188 2.98603C4.02217 2.8121 3.7436 2.80868 3.56967 2.97839C3.39574 3.14809 3.39232 3.42667 3.56203 3.60059L4.19188 2.98603ZM5.10574 5.1827C5.27544 5.35663 5.55402 5.36006 5.72794 5.19035C5.90187 5.02064 5.90529 4.74207 5.73559 4.56814L5.10574 5.1827ZM3.56583 6.44522C3.394 6.61705 3.394 6.89564 3.56583 7.06747C3.73766 7.2393 4.01625 7.2393 4.18808 7.06747L3.56583 6.44522ZM7.69833 3.55722C7.87016 3.38539 7.87016 3.1068 7.69833 2.93497C7.5265 2.76314 7.24791 2.76314 7.07608 2.93497L7.69833 3.55722ZM6.50964 4.12366L6.19852 3.81253L6.19852 3.81253L6.50964 4.12366ZM5.86996 4.14109L5.55883 4.45222L6.18108 5.07447L6.49221 4.76334L5.86996 4.14109ZM4.75452 5.87878L4.44339 5.56766L4.44339 5.56766L4.75452 5.87878ZM5.29606 5.95949L5.60719 5.64837L4.98493 5.02611L4.67381 5.33724L5.29606 5.95949ZM3.56203 3.60059L5.10574 5.1827L5.73559 4.56814L4.19188 2.98603L3.56203 3.60059ZM6.82077 4.43478L7.69833 3.55722L7.07608 2.93497L6.19852 3.81253L6.82077 4.43478ZM6.49221 4.76334L6.82077 4.43478L6.19852 3.81253L5.86996 4.14109L6.49221 4.76334ZM4.18808 7.06747L5.06564 6.18991L4.44339 5.56766L3.56583 6.44522L4.18808 7.06747ZM5.06564 6.18991L5.29606 5.95949L4.67381 5.33724L4.44339 5.56766L5.06564 6.18991Z"
                fill="#0BBD35"
            />
        </svg>
    ),
    AI_video: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.8332 4.66667L9.33317 7L12.8332 9.33333V4.66667Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.1665 3.5H2.33317C1.68884 3.5 1.1665 4.02233 1.1665 4.66667V9.33333C1.1665 9.97767 1.68884 10.5 2.33317 10.5H8.1665C8.81084 10.5 9.33317 9.97767 9.33317 9.33333V4.66667C9.33317 4.02233 8.81084 3.5 8.1665 3.5Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.31702 5.83789L5.07176 6.58657C5.0592 6.62545 5.03759 6.6608 5.00871 6.6897C4.97983 6.7186 4.9445 6.74024 4.90563 6.75283L4.15527 6.99963L4.90395 7.24489C4.94283 7.25745 4.97818 7.27906 5.00708 7.30794C5.03599 7.33682 5.05762 7.37215 5.07021 7.41102L5.31702 8.16138L5.56227 7.4127C5.57483 7.37382 5.59644 7.33847 5.62532 7.30957C5.6542 7.28066 5.68953 7.25903 5.7284 7.24644L6.47876 6.99963L5.73008 6.75438C5.6912 6.74182 5.65585 6.72021 5.62695 6.69133C5.59805 6.66245 5.57641 6.62712 5.56382 6.58825L5.31702 5.83789Z"
                stroke="#091E42"
                stroke-width="0.88"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
    payment_method: <CreditCardOutlined />,
    subscription: <DollarOutlined />,
    language: <GlobalOutlined />,
    code_snippets: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.4998 9.33203L12.8332 6.9987L10.4998 4.66536M3.49984 4.66536L1.1665 6.9987L3.49984 9.33203M8.45817 2.33203L5.5415 11.6654"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
    rating: (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.3055 1.76562L9.108 5.41729L13.1388 6.00646L10.2222 8.84729L10.9105 12.8606L7.3055 10.9648L3.7005 12.8606L4.38883 8.84729L1.47217 6.00646L5.503 5.41729L7.3055 1.76562Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
    date: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.83333 1.16797V3.5013M5.16667 1.16797V3.5013M2.25 5.83464H12.75M5.16667 8.16797H5.1725M7.5 8.16797H7.50583M9.83333 8.16797H9.83917M5.16667 10.5013H5.1725M7.5 10.5013H7.50583M9.83333 10.5013H9.83917M3.41667 2.33464H11.5833C12.2277 2.33464 12.75 2.85697 12.75 3.5013V11.668C12.75 12.3123 12.2277 12.8346 11.5833 12.8346H3.41667C2.77233 12.8346 2.25 12.3123 2.25 11.668V3.5013C2.25 2.85697 2.77233 2.33464 3.41667 2.33464Z"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),

    youtube: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_788_668)">
                <path
                    d="M13.2492 4.14875C13.1112 3.63945 12.7047 3.23839 12.1883 3.10228C11.2525 2.85491 7.5 2.85491 7.5 2.85491C7.5 2.85491 3.7475 2.85491 2.81167 3.10228C2.29538 3.23839 1.88874 3.63945 1.75075 4.14875C1.5 5.07183 1.5 6.99777 1.5 6.99777C1.5 6.99777 1.5 8.92368 1.75075 9.84684C1.88874 10.3561 2.29538 10.7571 2.81167 10.8933C3.7475 11.1406 7.5 11.1406 7.5 11.1406C7.5 11.1406 11.2525 11.1406 12.1883 10.8933C12.7047 10.7571 13.1112 10.3561 13.2492 9.84684C13.5 8.92368 13.5 6.99777 13.5 6.99777C13.5 6.99777 13.5 5.07183 13.2492 4.14875Z"
                    fill="#ED1D24"
                />
                <path
                    d="M6.27295 8.74609L9.40931 6.99757L6.27295 5.24889V8.74609Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_788_668">
                    <rect
                        width="12"
                        height="8.28571"
                        fill="white"
                        transform="translate(1.5 2.85547)"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
    redirect_to_url: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_2570_8538)">
                <path
                    d="M5.94417 9.81771H4.77751C4.00396 9.81771 3.26209 9.51042 2.71511 8.96344C2.16813 8.41646 1.86084 7.67459 1.86084 6.90104C1.86084 6.12749 2.16813 5.38563 2.71511 4.83865C3.26209 4.29167 4.00396 3.98438 4.77751 3.98438H5.94417M9.44417 3.98438H10.6108C11.3844 3.98438 12.1263 4.29167 12.6732 4.83865C13.2202 5.38563 13.5275 6.12749 13.5275 6.90104C13.5275 7.67459 13.2202 8.41646 12.6732 8.96344C12.1263 9.51042 11.3844 9.81771 10.6108 9.81771H9.44417M5.36084 6.90104H10.0275"
                    stroke="#091E42"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2570_8538">
                    <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
    long_text: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.25 3.5H12.75M2.25 7H12.75M2.25 10.5H12.75"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
    calendly: (
        <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.72448 9.80551C9.33624 10.1482 8.85138 10.5745 7.97224 10.5745H7.44653C6.81036 10.5745 6.23172 10.3446 5.81755 9.92774C5.41297 9.52044 5.19025 8.96301 5.19025 8.35789V7.64256C5.19025 7.03744 5.41297 6.48001 5.81755 6.07271C6.23172 5.65587 6.81036 5.42626 7.44653 5.42626H7.97224C8.85245 5.42626 9.33624 5.85228 9.72448 6.19493C10.1276 6.54818 10.4754 6.85693 11.4028 6.85693C11.5443 6.85689 11.6855 6.84567 11.8252 6.82337L11.822 6.81524C11.7664 6.67805 11.7013 6.5449 11.627 6.41678L11.0071 5.3489C10.7276 4.8675 10.3256 4.46775 9.84151 4.18983C9.35741 3.9119 8.80828 3.7656 8.2493 3.76563H7.00927C6.4503 3.7656 5.90117 3.9119 5.41707 4.18983C4.93297 4.46775 4.53096 4.8675 4.25144 5.3489L3.6316 6.41678C3.35218 6.89823 3.20508 7.44433 3.20508 8.00022C3.20508 8.55612 3.35218 9.10222 3.6316 9.58367L4.25144 10.6515C4.53096 11.133 4.93297 11.5327 5.41707 11.8106C5.90117 12.0885 6.4503 12.2348 7.00927 12.2348H8.2493C8.80828 12.2348 9.35741 12.0885 9.84151 11.8106C10.3256 11.5327 10.7276 11.133 11.0071 10.6515L11.627 9.58544C11.7013 9.45731 11.7664 9.32416 11.822 9.18697L11.8252 9.1792C11.6856 9.15614 11.5443 9.14432 11.4028 9.14387C10.4754 9.14387 10.1276 9.45085 9.72448 9.80587"
                fill="#006BFF"
            />
            <path
                d="M7.97198 6.07422H7.44627C6.47797 6.07422 5.8418 6.762 5.8418 7.6423V8.35763C5.8418 9.23794 6.47797 9.92572 7.44627 9.92572H7.97198C9.38286 9.92572 9.27204 8.49505 11.4026 8.49505C11.6044 8.49482 11.8059 8.51327 12.0043 8.55016C12.0689 8.18618 12.0689 7.81375 12.0043 7.44978C11.8059 7.48649 11.6044 7.50494 11.4026 7.50489C9.27133 7.50489 9.38286 6.07422 7.97198 6.07422Z"
                fill="#006BFF"
            />
            <path
                d="M13.2286 9.07345C12.8658 8.80918 12.4474 8.6303 12.0049 8.55028C12.0049 8.55417 12.0049 8.55735 12.0049 8.56088C11.9668 8.7718 11.9073 8.97833 11.8273 9.17731C12.1928 9.2336 12.5396 9.37564 12.8389 9.59167C12.8389 9.5945 12.8371 9.59803 12.8361 9.60121C12.4508 10.8419 11.6308 11.904 10.5251 12.5946C9.41933 13.2851 8.1012 13.5583 6.81012 13.3646C5.51904 13.1709 4.34067 12.523 3.48906 11.5387C2.63745 10.5544 2.16911 9.29895 2.16911 8.00045C2.16911 6.70195 2.63745 5.44652 3.48906 4.46222C4.34067 3.47792 5.51904 2.83005 6.81012 2.6363C8.1012 2.44255 9.41933 2.71578 10.5251 3.40634C11.6308 4.09691 12.4508 5.15901 12.8361 6.39969C12.8361 6.40287 12.8379 6.4064 12.8389 6.40923C12.5396 6.62529 12.1928 6.76722 11.8273 6.82324C11.9072 7.02249 11.9667 7.22924 12.0049 7.44037C12.0047 7.44366 12.0047 7.44697 12.0049 7.45026C12.4473 7.37038 12.8658 7.19161 13.2286 6.92745C13.5774 6.67063 13.5099 6.38062 13.457 6.20894C13.0257 4.81787 12.107 3.62683 10.8676 2.85224C9.62833 2.07766 8.15072 1.77093 6.70331 1.98779C5.2559 2.20466 3.93475 2.93072 2.97994 4.03406C2.02512 5.13739 1.5 6.54477 1.5 8.00045C1.5 9.45613 2.02512 10.8635 2.97994 11.9668C3.93475 13.0702 5.2559 13.7962 6.70331 14.0131C8.15072 14.23 9.62833 13.9232 10.8676 13.1487C12.107 12.3741 13.0257 11.183 13.457 9.79196C13.5099 9.62028 13.5774 9.33026 13.2286 9.07345Z"
                fill="#006BFF"
            />
            <path
                d="M11.8249 6.82343C11.6853 6.84649 11.544 6.85831 11.4025 6.85876C10.4751 6.85876 10.1273 6.55178 9.72452 6.19676C9.33593 5.85411 8.85213 5.42773 7.97193 5.42773H7.44622C6.80969 5.42773 6.23141 5.6577 5.81724 6.07454C5.41266 6.48184 5.18994 7.03927 5.18994 7.64439V8.35972C5.18994 8.96484 5.41266 9.52227 5.81724 9.92957C6.23141 10.3464 6.80969 10.576 7.44622 10.576H7.97193C8.85213 10.576 9.33593 10.15 9.72452 9.80734C10.1273 9.45409 10.4751 9.14535 11.4025 9.14535C11.544 9.14538 11.6852 9.15661 11.8249 9.17891C11.905 8.97999 11.9646 8.77344 12.0025 8.56249C12.0027 8.55896 12.0027 8.55542 12.0025 8.55189C11.804 8.5153 11.6026 8.49697 11.4008 8.49713C9.2695 8.49713 9.38104 9.92815 7.97015 9.92815H7.44445C6.47615 9.92815 5.83997 9.24002 5.83997 8.35972V7.64297C5.83997 6.76267 6.47615 6.07489 7.44445 6.07489H7.97015C9.38104 6.07489 9.27021 7.5052 11.4008 7.5052C11.6026 7.50555 11.804 7.48722 12.0025 7.45045C12.0025 7.44727 12.0025 7.44409 12.0025 7.44056C11.9643 7.22943 11.9048 7.02268 11.8249 6.82343Z"
                fill="#0AE8F0"
            />
            <path
                d="M11.8249 6.82343C11.6853 6.84649 11.544 6.85831 11.4025 6.85876C10.4751 6.85876 10.1273 6.55178 9.72452 6.19676C9.33593 5.85411 8.85213 5.42773 7.97193 5.42773H7.44622C6.80969 5.42773 6.23141 5.6577 5.81724 6.07454C5.41266 6.48184 5.18994 7.03927 5.18994 7.64439V8.35972C5.18994 8.96484 5.41266 9.52227 5.81724 9.92957C6.23141 10.3464 6.80969 10.576 7.44622 10.576H7.97193C8.85213 10.576 9.33593 10.15 9.72452 9.80734C10.1273 9.45409 10.4751 9.14535 11.4025 9.14535C11.544 9.14538 11.6852 9.15661 11.8249 9.17891C11.905 8.97999 11.9646 8.77344 12.0025 8.56249C12.0027 8.55896 12.0027 8.55542 12.0025 8.55189C11.804 8.5153 11.6026 8.49697 11.4008 8.49713C9.2695 8.49713 9.38104 9.92815 7.97015 9.92815H7.44445C6.47615 9.92815 5.83997 9.24002 5.83997 8.35972V7.64297C5.83997 6.76267 6.47615 6.07489 7.44445 6.07489H7.97015C9.38104 6.07489 9.27021 7.5052 11.4008 7.5052C11.6026 7.50555 11.804 7.48722 12.0025 7.45045C12.0025 7.44727 12.0025 7.44409 12.0025 7.44056C11.9643 7.22943 11.9048 7.02268 11.8249 6.82343Z"
                fill="#0AE8F0"
            />
        </svg>
    ),
    status: (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.64339 3.49935H13.0601M6.64339 6.99935H13.0601M6.64339 10.4993H13.0601M2.56006 3.49935L3.14339 4.08268L4.31006 2.91602M2.56006 6.99935L3.14339 7.58268L4.31006 6.41602M2.56006 10.4993L3.14339 11.0827L4.31006 9.91602"
                stroke="#091E42"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    ),
};

export const QUESTION_OBJECTS = {
    email: {
        type: "email",
        label: "Email",
        icon: QUESTION_ICONS.email,
        color: "#F9F5FF",
        textColor: "#6A32EE",
    },
    phone_number: {
        type: "phone_number",
        label: "Phone Number",
        icon: QUESTION_ICONS.phone_number,
        color: "#F9F5FF",
        textColor: "#6A32EE",
    },
    contact_info: {
        type: "contact_info",
        label: "Contact Info",
        icon: QUESTION_ICONS.contact_info,
        color: "#F9F5FF",
        textColor: "#6A32EE",
    },
    country: {
        type: "country",
        label: "Country",
        icon: QUESTION_ICONS.country,
        color: "#F9F5FF",
        textColor: "#6A32EE",
    },
    multiple_choice: {
        type: "multiple_choice",
        label: "Multiple Choice",
        icon: QUESTION_ICONS.multiple_choice,
        color: "#ECFDF3",
        textColor: "#0BBD35",
    },
    dropdown: {
        type: "dropdown",
        label: "Dropdown",
        icon: QUESTION_ICONS.dropdown,
        color: "#ECFDF3",
        textColor: "#0BBD35",
    },
    legal: {
        type: "legal",
        label: "Legal",
        icon: QUESTION_ICONS.legal,
        color: "#F1F2F4",
        textColor: "#44546F",
    },
    short_text: {
        type: "short_text",
        label: "Short Text",
        icon: QUESTION_ICONS.short_text,
        color: "#FFFAEB",
        textColor: "#FF4A00",
    },
    section: {
        type: "section",
        label: "Section",
        icon: QUESTION_ICONS.section,
        color: "#FFE2A7",
        color: "#FFFAEB",
        textColor: "#FF4A00",
    },
    checkbox: {
        type: "checkbox",
        label: "Checkbox",
        icon: <CheckSquareOutlined />,
        color: "#8BC34A",
    },

    ai_statement: {
        type: "ai_statement",
        label: "AI statement",
        icon: QUESTION_ICONS.ai_statement,
        color: "#EEF4FF",
        textColor: "#2A2DEB",
    },
    date: {
        type: "date",
        label: "Date",
        icon: QUESTION_ICONS.date,
        color: "#ECFDF3",
        textColor: "#0BBD35",
    },
    status: {
        type: "status",
        label: "State",
        icon: QUESTION_ICONS.status,
        color: "#F1F2F4",
        textColor: "#626F86",
    },
    file_upload: {
        type: "file_upload",
        label: "File Upload",
        icon: QUESTION_ICONS.file_upload,
        color: "#F1F2F4",
        textColor: "#626F86",
    },
};
export const QUESTION_TYPES = {
    MULTIPLE_CHOICE: "multiple_choice",
    SHORT_TEXT: "short_text",
    EMAIL: "email",
    DROPDOWN: "dropdown",
    PHONE_NUMBER: "phone_number",
    SECTION: "section",
    CONTACT_INFORMATION: "contact_info",
    CHECKBOX: "checkbox",
    MULTIPLE_FIELD: "multiple_field",
    LEGAL: "legal",
    COUNTRY: "country",
    AI_STATEMENT: "ai_statement",
    STATEMENT: "statement",
    VIDEO: "video",
    AI_GRAPH: "AI_graph",
    AI_IMAGE: "AI_image",
    FILE_UPLOAD: "file_upload",
    YES_NO: "yes_no",
    AI_VIDEO: "AI_video",
    LANGUAGE_PREFERENCE: "language",
    SUBSCRIPTION_TYPE: "subscription",
    PAYMENT_TYPE: "payment_method",
    REDIRECT: "redirect_to_url",
    RATING: "rating",
    DATE: "date",
    CODES: "code_snippets",
    FACEBOOK: "facebook",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    LINKEDIN: "linkedin",
    YOUTUBE: "youtube",
    WEBSITE: "website",
    LONG_TEXT: "long_text",
    CALENDLY: "calendly",
};

export const FIELD_TYPE_GROUPS = {
    Recommended: [
        QUESTION_TYPES.SHORT_TEXT,
        QUESTION_TYPES.MULTIPLE_CHOICE,
        QUESTION_TYPES.DROPDOWN,
        QUESTION_TYPES.AI_STATEMENT,
    ],
    "Contact Info": [
        QUESTION_TYPES.EMAIL,
        QUESTION_TYPES.PHONE_NUMBER,
        QUESTION_TYPES.CONTACT_INFORMATION,
        QUESTION_TYPES.COUNTRY,
    ],

    Choices: [
        QUESTION_TYPES.MULTIPLE_CHOICE,
        QUESTION_TYPES.DROPDOWN,
        QUESTION_TYPES.YES_NO,
        QUESTION_TYPES.DATE,
    ],
    "AI Features": [
        QUESTION_TYPES.AI_STATEMENT,
        QUESTION_TYPES.AI_GRAPH,
        QUESTION_TYPES.AI_IMAGE,
        QUESTION_TYPES.AI_VIDEO,
    ],
    "Text and Video": [
        QUESTION_TYPES.SHORT_TEXT,
        QUESTION_TYPES.LONG_TEXT,
        QUESTION_TYPES.VIDEO,
        QUESTION_TYPES.SECTION,
    ],

    "Welcome and End Screens": [QUESTION_TYPES.LEGAL, QUESTION_TYPES.REDIRECT],

    "Embeddings and Uploads": [
        QUESTION_TYPES.CODES,
        QUESTION_TYPES.YOUTUBE,
        QUESTION_TYPES.FILE_UPLOAD,
        QUESTION_TYPES.CALENDLY,
    ],
};

export const FIELD_TYPE_OPTIONS = {
    [QUESTION_TYPES.MULTIPLE_CHOICE]: {
        type: QUESTION_TYPES.MULTIPLE_CHOICE,
        question_number: "",
        label: "Multiple Choice",
        title: "",
        properties: {
            description: "",
            choices: [{ id: uuidv4(), label: "" }],
            allow_multiple_selection: false,
            button_text: "Next",
        },
        validations: {
            required: false,
        },
        attachment: {
            link: "",
            type: "image",
            placement: "right",
        },
    },
    [QUESTION_TYPES.SHORT_TEXT]: {
        question_number: "",
        type: QUESTION_TYPES.SHORT_TEXT,
        label: "Short Text",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
        validations: {
            required: false,
        },
        attachment: {
            link: "",
            type: "image",
            placement: "right",
        },
    },
    [QUESTION_TYPES.EMAIL]: {
        type: QUESTION_TYPES.EMAIL,
        label: "Email",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
        validations: {
            required: false,
        },
    },
    [QUESTION_TYPES.DROPDOWN]: {
        type: QUESTION_TYPES.DROPDOWN,
        label: "Dropdown",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
    },
    [QUESTION_TYPES.PHONE_NUMBER]: {
        type: QUESTION_TYPES.PHONE_NUMBER,
        label: "Phone Number",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
    },
    [QUESTION_TYPES.SECTION]: {
        type: QUESTION_TYPES.SECTION,
        label: "Section",
        title: "",
        properties: {
            description: "",
        },
    },
    [QUESTION_TYPES.FILE_UPLOAD]: {
        type: QUESTION_TYPES.FILE_UPLOAD,
        label: "File Upload",
        title: "",
        properties: {
            description: "",
            allowed_file_types: ["image", "audio", "video", "document"],
            button_text: "Upload File",
        },
    },
    [QUESTION_TYPES.CONTACT_INFORMATION]: {
        type: QUESTION_TYPES.CONTACT_INFORMATION,
        label: "Contact Info",
        title: "",
        properties: {
            description: "",
            fields: [
                {
                    id: uuidv4(),
                    type: QUESTION_TYPES.SHORT_TEXT,
                    title: `First Name`,
                    properties: {
                        description: "",
                        fields: [],
                    },
                    validations: {
                        required: "true",
                    },
                },
                {
                    id: uuidv4(),
                    type: QUESTION_TYPES.SHORT_TEXT,
                    title: `Last Name`,
                    properties: {
                        description: "",
                        fields: [],
                    },
                    validations: {
                        required: "true",
                    },
                },
            ],
            button_text: "Next",
        },
    },
    [QUESTION_TYPES.LEGAL]: {
        type: QUESTION_TYPES.LEGAL,
        label: "Legal",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
    },
    [QUESTION_TYPES.COUNTRY]: {
        type: QUESTION_TYPES.COUNTRY,
        label: "Country",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
    },
    [QUESTION_TYPES.AI_STATEMENT]: {
        type: QUESTION_TYPES.AI_STATEMENT,
        label: "AI statement",
        title: "",
        properties: {
            description: "",
            button_text: "Next",
        },
        attachment: {
            link: "",
            type: "image",
            placement: "right",
        },
    },
    /*   [QUESTION_TYPES.STATEMENT]: {
    type: QUESTION_TYPES.STATEMENT,
    label: "Statement",
  }, */
};

export const FIELD_TYPE_SETTINGS = {
    [QUESTION_TYPES.SHORT_TEXT]: {
        validations: {
            required: "Switch",
        },
        attachment: {
            link: "Image",
            placement: "Placement",
            type: "image",
        },
    },
    [QUESTION_TYPES.MULTIPLE_CHOICE]: {
        properties: {
            allow_multiple_selection: "Switch",
        },
        validations: {
            required: "Switch",
        },
        attachment: {
            link: "Image",
            placement: "Placement",
            type: "image",
        },
    },
    [QUESTION_TYPES.EMAIL]: {
        validations: {
            required: "Switch",
        },
    },
    [QUESTION_TYPES.PHONE_NUMBER]: {
        validations: {
            required: "Switch",
        },
    },
    [QUESTION_TYPES.DROPDOWN]: {
        properties: {
            choices: "ArrayInput",
        },
        validations: {
            required: "Switch",
        },
    },
    [QUESTION_TYPES.SECTION]: {},
    [QUESTION_TYPES.CONTACT_INFORMATION]: {
        properties: {
            fields: "ArrayObject",
        },
    },
    [QUESTION_TYPES.CHECKBOX]: {
        properties: {
            checkbox_label: "ShortText",
        },
        validations: {
            required: "Switch",
        },
    },
    [QUESTION_TYPES.COUNTRY]: {
        validations: {
            required: "Switch",
        },
    },
    [QUESTION_TYPES.LEGAL]: {},
    [QUESTION_TYPES.AI_STATEMENT]: {
        title: "AiStatement",
        label: "AI Statement",
        /* properties: {
            knowledge_base: "KnowledgeBase",
        }, */
        attachment: {
            link: "Image",
            placement: "Placement",
            type: "image",
        },
    },
    [QUESTION_TYPES.STATEMENT]: {},
};
export const SETTING_CONTEXT = {
    FIELD: "Field",
    GLOBAL: "Global",
};

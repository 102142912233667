import React, { useEffect, useMemo, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

// Components
import { DataTable } from "../Components/ResponsesTable/DataTable.tsx";
import { Checkbox } from "../Components/ui/checkbox.tsx";
import Header from "../Components/ResponsesTable/Header.tsx";
import Cell from "../Components/ResponsesTable/Cell.tsx";
import ResponseModal from "../Components/ResponseModal.tsx";

import { useApi } from "../Api/useApi.ts";

import { useFormSubmissionsContext } from "../Contexts/FormSubmissionsContext.tsx";

const CheckboxHeader = {
    id: "select",
    header: ({ table }) => (
        <Checkbox
            checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
        />
    ),
    cell: ({ row }) => (
        <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
        />
    ),
    width: 10,
    enableSorting: false,
    enableHiding: false,
};

function FormResponsesView() {
    const api = useApi();
    const { urlId } = useParams();
    const [searchParams] = useSearchParams();
    const [selectedRow, setSelectedRow] = useState(null);

    const query = searchParams.get("query");
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const type = searchParams.get("type");

    const {
        fields,
        loading,
        setTotalResponses,
        setResponses,
        activeRow,
        setActiveRow,
    } = useFormSubmissionsContext();

    const {
        data: formResponses,
        isFetched: isFormResponsesFetched,
        fetchNextPage,
        error,
    } = useInfiniteQuery({
        queryKey: ["formResponses", query, from, to, type],
        queryFn: ({ pageParam = 1 }) =>
            api.forms.fetchFormResponses(
                urlId,
                { page: pageParam, size: 50 },
                {
                    query,
                    date: { from, to },
                    responseType: type,
                },
            ),
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length === 0) {
                return undefined;
            }
            return lastPageParam + 1;
        },
        getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
            if (firstPageParam <= 1) {
                return undefined;
            }
            return firstPageParam - 1;
        },
    });

    const getColumns = () => {
        const filteredFields = fields
            .filter((field) => field.checked)
            .filter((field) => field.type !== "section");

        const columns = filteredFields?.map((field, i) => {
            return {
                id: i + 1,
                accessorKey: field.title,
                header: ({ column }) => {
                    return (
                        <Header
                            title={field.title}
                            type={field.type}
                            onClick={() =>
                                column.toggleSorting(
                                    column.getIsSorted() === "asc",
                                )
                            }
                        />
                    );
                },
                cell: ({ row }) => {
                    return (
                        <Cell
                            data={row.original?.[field.title]}
                            type={field.type}
                            onClick={() => {}}
                        />
                    );
                },
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
            };
        });
        columns.unshift(CheckboxHeader);
        return columns;
    };

    const columns = useMemo(() => getColumns(), [fields]);

    const getData = () => {
        if (
            error ||
            !isFormResponsesFetched ||
            !formResponses?.pages ||
            formResponses?.pages.length === 0
        )
            return [];

        const out = [];
        // Loop through all pages
        for (const page of formResponses?.pages) {
            // Add null check for page.data
            if (!page?.data?.responses) continue;

            // Loop through the responses in each page
            for (const response of page?.data?.responses) {
                const qaArray = response.data;
                const entry = {
                    id: response?.response_id,
                    Date: response?.updated_at,
                    State: response?.type,
                };
                // Loop through the fields of each response
                for (const field of fields) {
                    const question = qaArray.find(
                        (qa) => qa.question.question_id === field.id,
                    );
                    const answers = question?.answer ?? [];
                    if (field.title === "Date")
                        entry["Date"] = response?.updated_at;
                    else if (field.title === "State")
                        entry["State"] = response?.type;
                    else if (field?.type === "file_upload")
                        entry[field?.title] = answers;
                    else
                        entry[field?.title] = answers?.map(
                            (answer) => answer?.answer_text,
                        );
                }
                out.push(entry);
            }
        }
        return out;
    };

    const data = getData();

    useEffect(() => {
        if (isFormResponsesFetched) {
            const allFetchedResponses = [];
            for (const page of formResponses?.pages) {
                if (Array.isArray(page?.data?.responses))
                    allFetchedResponses.push(...page?.data?.responses);
            }
            setResponses(allFetchedResponses);
            setTotalResponses(formResponses?.pages?.[0]?.data?.total);
        }
    }, [formResponses, isFormResponsesFetched]);

    return (
        <div className="relative h-[calc(100vh-6rem)] overflow-auto">
            <DataTable
                data={data ?? []}
                columns={columns}
                isLoaded={isFormResponsesFetched && !loading}
                total={formResponses?.data?.total ?? 0}
                onSortingChange={(sorting) => {}}
                onScrollEnd={() => {
                    console.log("scroll end");
                    fetchNextPage();
                }}
                onRowClick={setSelectedRow}
            />
            {activeRow && (
                <ResponseModal
                    open={!!activeRow}
                    onClose={() => setActiveRow(null)}
                />
            )}
        </div>
    );
}

export default FormResponsesView;

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { useClerk } from "@clerk/clerk-react";
import { ROUTES } from "../Constants/routes";
import DefaultLoading from "../Components/DashboardComponents/DefaultLoading";

function RootLayout() {
    const navigate = useNavigate();
    const { getToken, userId } = useAuth();
    const clerk = useClerk();
    const [loadingToken, setLoadingToken] = useState(true);

    useEffect(() => {
        const checkToken = async () => {
            try {
                const token = await getToken();
                const isRoot = location.pathname === "/";
                const isLogin = location.pathname === "/login";
                const isRegister = location.pathname === "/register";
                const isSSOCallback =
                    location.pathname === "/sign-up/sso-callback";
                if (!token && !userId && !isRegister && !isSSOCallback) {
                    navigate(ROUTES.LOGIN);
                } else if (
                    token &&
                    userId &&
                    (isRoot || isLogin || isRegister)
                ) {
                    setLoadingToken(false);
                    navigate(ROUTES.DASHBOARD);
                } else if (token && userId) {
                    setLoadingToken(false);
                } else {
                    setLoadingToken(false);
                }
            } catch (error) {
                console.error("Failed to fetch token", error);
                navigate(ROUTES.LOGIN);
            }
        };

        checkToken();
    }, [getToken, navigate, userId]);

    if (loadingToken && !clerk.loaded) {
        return <DefaultLoading loadingToken={loadingToken} />;
    }

    return (
        <main>
            <Outlet />
        </main>
    );
}

export default RootLayout;

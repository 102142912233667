import React, { useState, useEffect } from 'react';
import { Typography, Flex } from 'antd';
import { Button } from 'antd';
import { motion } from 'framer-motion';
import { EnterOutlined } from '@ant-design/icons';
import { useFormContext } from '../../Contexts/FormContext';

export const FormNextButton = ({
    index,
    children,
    isEndScreen = false,
    ...rest
}) => {
    const { nextButtonPress, formTheme, currentQuestion } = useFormContext();

    const constTheme = formTheme;

    let button_size;
    switch (constTheme?.button_size) {
        case 'small':
            button_size = 'sm';
            break;
        case 'large':
            button_size = 'default';
            break;
        default:
            button_size = 'lg';
            break;
    }

    return (
        <motion.div
            key={`next ${index}`}
            /*    initial={{ opacity: 0, translateY: 400 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 40 }}
      transition={{ delay: 0, duration: 0.3 }} */

            /* initial={{ opacity: 0, translateY: 40 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0, duration: 0.15 }} */
        >
            <Flex style={{ alignItems: 'center' }} gap="large">
                <Button
                    size={constTheme?.button_size}
                    onClick={nextButtonPress}
                    style={{
                        backgroundColor: constTheme?.button_color,
                        color: constTheme?.button_text_color,
                        borderRadius: `${constTheme?.rounded_corners}px`,
                        fontWeight:
                            constTheme?.is_bold === 'true'
                                ? 'bold'
                                : constTheme?.is_bold === 'false'
                                ? 'normal'
                                : 'bold',
                    }}
                    // type="primary"
                    {...rest}
                    // icon={<CheckOutlined color="black"/>}
                >
                    {children}
                </Button>

                {!isEndScreen && (
                    <Flex gap="small">
                        <Typography.Text
                            style={{
                                fontSize: 12,
                                fontFamily: `${constTheme?.font_family?.family}, ${constTheme?.font_family?.category}`,
                            }}
                        >
                            {'press '}
                            <Typography.Text
                                style={{
                                    fontSize: 12,
                                    fontFamily: `${constTheme?.font_family?.family}, ${constTheme?.font_family?.category}`,
                                }}
                                strong
                            >
                                Enter
                            </Typography.Text>
                        </Typography.Text>
                        <EnterOutlined />
                    </Flex>
                )}
            </Flex>
        </motion.div>
    );
};

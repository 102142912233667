import React, { useState } from "react";

import { Link } from "react-router-dom";

import { format } from "date-fns";

import {
    Sheet,
    SheetContent,
    SheetFooter,
    SheetHeader,
} from "../Components/ui/sheet.tsx";
import { Button } from "./ui/button.tsx";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DownloadIcon,
    TrashIcon,
    XIcon,
} from "lucide-react";
import { useFormSubmissionsContext } from "../Contexts/FormSubmissionsContext.tsx";
import {
    QUESTION_OBJECTS,
    QUESTION_TYPES,
} from "../Constants/questionTypes.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import DeleteResponseConfirmationModal from "./DeleteResponseConfimationModal.tsx";
import Markdown from "react-markdown";

type ResponseModalProps = {
    open: boolean;
    onClose: () => void;
};

function ResponseModal({ open, onClose }: ResponseModalProps) {
    const { form, fields, totalResponses, responses, activeRow, setActiveRow } =
        useFormSubmissionsContext();

    const getResponse = (responseId: string) => {
        return responses?.find(
            (response) => response?.response_id === responseId,
        );
    };

    const getQuestionFields = (response: any) => {
        const out: any[] = [];
        for (const field of fields) {
            const question = response?.data?.find(
                (qa) => qa.question.question_id === field.id,
            );
            const answers = question?.answer ?? [];

            const questionTitle = field?.title;
            const questionType = field?.type;
            if (questionType === "date")
                out.push({
                    id: field?.id,
                    title: "Date",
                    type: "date",
                    answer: response?.updated_at
                        ? format(new Date(response.updated_at), "PP p")
                        : "",
                });
            else if (questionType === "status")
                out.push({
                    id: field?.id,
                    title: "Response State",
                    type: "status",
                    answer: response?.type,
                });
            else if (questionType === "file_upload")
                out.push({
                    id: field?.id,
                    title: questionTitle,
                    type: questionType,
                    answer: answers,
                });
            else if (questionType === "ai_statement")
                out.push({
                    id: field?.id,
                    title: "AI Statement",
                    type: questionType,
                    answer: answers?.map((answer) => answer?.answer_text),
                });
            else
                out.push({
                    id: field?.id,
                    title: questionTitle,
                    type: questionType,
                    answer: answers?.map((answer) => answer?.answer_text),
                });
        }
        return out;
    };

    const api = useApi();
    const queryClient = useQueryClient();
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
        useState(false);
    const [response, setResponse] = useState<any>(getResponse(activeRow));
    const [questionFields, setQuestionFields] = useState<any[]>(
        getQuestionFields(response),
    );

    const { mutate: deleteResponsesMutation, isPending } = useMutation({
        mutationFn: ({
            formId,
            responseIds,
        }: {
            formId: string;
            responseIds: string[];
        }) => api.responses.deleteResponses(formId, responseIds),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["formResponses"] });
            setOpenDeleteConfirmationModal(false);
            handleOnNext();
        },
        onError: (error) => {
            //messageApi.error("Could not delete form");
            console.error("Could not delete form", error);
        },
    });

    const { mutate: exportResponsesMutation, isPending: isExporting } =
        useMutation({
            mutationFn: ({
                workspaceId,
                formId,
                responseIds,
            }: {
                formId?: string;
                workspaceId: string;
                responseIds?: string[];
            }) => api.forms.exportResponses(workspaceId, formId, responseIds),
            onSuccess: (data) => {
                const exportFile = data?.data?.file_url;
                if (exportFile) {
                    // Create an anchor element
                    const anchor = document.createElement("a");

                    // Set the href to the file URL
                    anchor.href = exportFile;

                    // Set the download attribute without a filename to keep the original file name
                    anchor.setAttribute("download", "");

                    // Append the anchor to the body (optional, for some browsers)
                    document.body.appendChild(anchor);

                    // Trigger a click event to start the download
                    anchor.click();

                    // Remove the anchor from the DOM
                    document.body.removeChild(anchor);
                }
                queryClient.invalidateQueries({ queryKey: ["responses"] });
            },
        });

    const currentIndex = responses?.findIndex(
        (res) => res?.response_id === response?.response_id,
    );

    const handleOnNext = () => {
        const currentIndex = responses?.findIndex(
            (res) => res?.response_id === response?.response_id,
        );
        const nextResponseIndex = currentIndex + 1;
        if (nextResponseIndex > responses?.length) return;

        const nextResponse = responses[nextResponseIndex];
        if (!nextResponse) return;

        setResponse(nextResponse);
        setQuestionFields(getQuestionFields(nextResponse));
        setActiveRow(nextResponse?.response_id);
    };

    const handleOnPrev = () => {
        const currentIndex = responses?.findIndex(
            (res) => res?.response_id === response?.response_id,
        );
        const prevResponseIndex = currentIndex - 1;
        if (prevResponseIndex < 0) return;

        const prevResponse = responses[prevResponseIndex];
        if (!prevResponse) return;

        setResponse(prevResponse);
        setQuestionFields(getQuestionFields(prevResponse));
        setActiveRow(prevResponse?.response_id);
    };

    const handleOnExport = () => {
        exportResponsesMutation({
            workspaceId: sessionStorage.getItem("selectedWorkSpace")!,
            formId: form?._id,
            responseIds: [response?.response_id],
        });
    };

    const handleOnDelete = () => {
        deleteResponsesMutation({
            formId: form?._id,
            responseIds: [response?.response_id],
        });
    };

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetContent className="absolute top-[59px] h-[calc(100vh-59px)] rounded-l-[12px] w-[400px] [&>button]:hidden p-4">
                <SheetHeader className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <Button
                            variant="outline"
                            size="sm"
                            className="w-fit"
                            onClick={handleOnPrev}
                        >
                            <ChevronLeftIcon className="w-4 h-4" />
                        </Button>
                        <Button
                            variant="outline"
                            size="sm"
                            className="w-fit"
                            onClick={handleOnNext}
                        >
                            <ChevronRightIcon className="w-4 h-4" />
                        </Button>
                        <div className="px-2">
                            <p className="text-xs">Response</p>
                            <p className="text-gray-600 text-xs">
                                {currentIndex + 1} of {totalResponses}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <Button
                            variant="outline"
                            size="sm"
                            className="w-fit"
                            onClick={handleOnExport}
                            disabled={isExporting}
                        >
                            <DownloadIcon className="w-4 h-4" />
                        </Button>
                        <Button
                            variant="outline"
                            size="sm"
                            className="w-fit"
                            onClick={onClose}
                        >
                            <XIcon className="w-4 h-4" />
                        </Button>
                    </div>
                </SheetHeader>
                <div className="h-[calc(100%-100px)] w-full overflow-y-auto overflow-x-hidden no-scrollbar flex flex-col gap-4 mt-6">
                    {questionFields?.map((field) => (
                        <Field
                            key={field?.id}
                            question={field?.title}
                            questionType={field?.type}
                            answers={field?.answer}
                        />
                    ))}
                </div>

                <SheetFooter>
                    <Button
                        variant="outline"
                        size="sm"
                        disabled={isPending}
                        className="w-fit"
                        onClick={() => setOpenDeleteConfirmationModal(true)}
                    >
                        <TrashIcon className="w-4 h-4" />
                    </Button>
                </SheetFooter>
                <DeleteResponseConfirmationModal
                    open={openDeleteConfirmationModal}
                    loading={isPending}
                    onCancel={() => setOpenDeleteConfirmationModal(false)}
                    onDelete={handleOnDelete}
                />
            </SheetContent>
        </Sheet>
    );
}

export default ResponseModal;

type FieldProps = {
    question: string;
    questionType: string;
    answers: any;
};

function Field({ question, questionType, answers }: FieldProps) {
    const q = QUESTION_OBJECTS[questionType];
    const Icon = q?.icon;
    const color = "#F1F2F4";

    if (questionType === QUESTION_TYPES.FILE_UPLOAD)
        return <FileUploadField question={question} files={answers} />;

    const answersStr = Array.isArray(answers) ? answers?.join(", ") : answers;

    if (questionType === QUESTION_TYPES.AI_STATEMENT)
        return <AIStatementField text={answersStr} />;

    return (
        <div className="flex flex-row items-start gap-2">
            <div
                className={`bg-[${color}] w-6 h-6 flex items-center justify-center rounded-md`}
            >
                {Icon}
            </div>
            <div className="max-w-sm">
                <p className="text-sm text-muted-foreground max-w-[320px]">
                    {question}
                </p>
                <p className="mt-1 text-sm max-w-[320px] text-primary">
                    {answersStr || "-"}
                </p>
            </div>
        </div>
    );
}

type FileUploadFieldProps = {
    question: string;
    files: any[];
};

function FileUploadField({ question, files }: FileUploadFieldProps) {
    const q = QUESTION_OBJECTS["file_upload"];
    const Icon = q.icon;
    const color = "#F1F2F4";

    return (
        <div className="flex flex-row items-start gap-2">
            <span
                className={`bg-[${color}] w-6 h-6 flex items-center justify-center rounded-md`}
            >
                {Icon}
            </span>
            <div>
                <p className="text-sm text-muted-foreground">{question}</p>
                {files.length === 0 && (
                    <p className="mt-1 text-sm text-primary">No documents</p>
                )}
                <div className="flex flex-col">
                    {files.map((file) => (
                        <div className="flex flex-row items-center gap-2">
                            <p className="text-sm">{file?.file_name}</p>
                            <Link to={file?.file_url} target="_blank">
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="w-7 h-7 p-1"
                                >
                                    <DownloadIcon className="w-3 h-3" />
                                </Button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

type AIStatementFieldProps = {
    text: string; // Markdown text
};

function AIStatementField({ text }: AIStatementFieldProps) {
    const q = QUESTION_OBJECTS["file_upload"];
    const Icon = q.icon;
    const color = "#F1F2F4";

    return (
        <div className="flex flex-row items-start gap-2">
            <span
                className={`bg-[${color}] w-6 h-6 flex items-center justify-center rounded-md`}
            >
                {Icon}
            </span>
            <div className="max-w-sm">
                <p className="text-sm text-muted-foreground max-w-[320px]">
                    AI Statement
                </p>
                <Markdown className="text-sm break-words max-w-[320px]">
                    {text}
                </Markdown>
            </div>
        </div>
    );
}

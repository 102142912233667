import React, { useState } from "react";

import { format } from "date-fns";

import Markdown from "react-markdown";

// Components
import { Button } from "../ui/button.tsx";
import { Badge } from "../ui/badge.tsx";
import { Input } from "../ui/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover.tsx";
import { HeaderTypes } from "./Header.tsx";

import { DownloadIcon, Plus, X } from "lucide-react";

import { cn } from "../../lib/utils.js";

export type CellTypes = HeaderTypes;

type DefaultCellTypes = "tags" | "date";

type CellProps = {
    data: string[] | string;
    type: DefaultCellTypes | CellTypes;
    onClick: () => void;
};

function Cell({ data, type, onClick }: CellProps) {
    if (type === "status") return <Status status={data as any} />;

    if (type === "tags")
        return <Tags tags={[]} onAdd={() => {}} onDelete={() => {}} />;

    if (type === "date") return <DateCell date={data as string} />;

    if (type === "file_upload") return <FileUpload files={data as any[]} />;

    const answersArr = Array.isArray(data) ? data : [data];
    const answers = answersArr.join(", ");

    if (type === "ai_statement") return <AIStatement text={answers} />;

    return <p className="max-w-[400px]">{answers}</p>;
}

export default Cell;

type HeaderVariantProps = Pick<CellProps, "data" | "onClick">;

type TagsProps = {
    tags: string[];
    onAdd: (tag: string) => void;
    onDelete: (tag: string) => void;
};

function Tags({ tags: _tags, onAdd, onDelete }: TagsProps) {
    const [tags, setTags] = useState<string[]>(_tags);
    const [tag, setTag] = useState("");
    const [open, setOpen] = useState(false);

    return (
        <Popover open={open} onOpenChange={setOpen} modal={false}>
            <PopoverTrigger
                asChild
                autoFocus={false}
                className="relative flex flex-row flex-wrap gap-1 w-full h-full py-0 my-0 border-none outline-none"
            >
                <div className="max-w-xs">
                    {tags.length === 0 && (
                        <Button
                            variant="link"
                            onClick={() => onAdd(tag)}
                            className="w-fit h-full text-xs text-gray-500 hover:no-underline"
                        >
                            <Plus className="w-3 h-3 mr-1" /> Add tag
                        </Button>
                    )}
                    {tags.map((tag) => (
                        <Tag tag={tag} />
                    ))}
                </div>
            </PopoverTrigger>
            <PopoverContent className="absolute -top-full -left-[150px] flex flex-row flex-wrap gap-1 p-2">
                {tags.map((tag) => (
                    <Tag
                        tag={tag}
                        onDelete={() => {
                            setTags(tags.filter((t) => t !== tag));
                            onDelete(tag);
                        }}
                    />
                ))}
                <Input
                    autoFocus={true}
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && tag.trim()) {
                            setTags([...tags, tag]);
                            setTag("");
                            onAdd(tag);
                        }
                    }}
                    className="outline-none border-none focus:outline-none focus:border-none focus-visible:ring-transparent w-20 h-5 px-1 rounded-none"
                />
            </PopoverContent>
        </Popover>
    );
}

type TagProps = {
    tag: string;
    onDelete?: (tag: string) => void;
};

function Tag({ tag, onDelete }: TagProps) {
    return (
        <Badge variant="outline" className="rounded-md">
            {tag}
            {onDelete && (
                <Button
                    variant="ghost"
                    size="icon"
                    className="w-5 h-5 ml-1"
                    onClick={() => onDelete(tag)}
                >
                    <X className="w-3 h-3" />
                </Button>
            )}
        </Badge>
    );
}

type DateCellProps = {
    date: string;
};

function DateCell({ date }: DateCellProps) {
    const formattedDate = format(date, "dd LLL yyyy");
    // const formattedTime = format(date, "HH:mm");

    return (
        <div>
            <p>{formattedDate}</p>
            {/* <p>{formattedTime}</p> */}
        </div>
    );
}

type StatusCellProps = {
    status: "completed" | "partial" | "started";
};

function Status({ status }: StatusCellProps) {
    if (status === "started")
        return (
            <Badge
                variant="outline"
                className="text-xs text-gray-600 rounded-sm bg-gray-100 py-1 px-2"
            >
                Started
            </Badge>
        );

    if (status === "partial")
        return (
            <Badge
                variant="outline"
                className="text-xs text-gray-600 rounded-sm bg-gray-100 py-1 px-2"
            >
                Partial
            </Badge>
        );

    if (status === "completed")
        return (
            <Badge
                variant="outline"
                className="text-xs text-[#1E40AF] rounded-sm bg-[#DBEAFE] py-1 px-2"
            >
                Completed
            </Badge>
        );

    return null;
}

type FileUploadProps = {
    files: any[];
};

function FileUpload({ files }: FileUploadProps) {
    const getName = () => {
        if (files.length === 0) return "No uploads";
        if (files.length === 1) return files[0]?.file_name;
        return `${files.length} Documents`;
    };

    return (
        <div className="flex flex-row items-center gap-2 justify-between">
            <p className={cn("text-xs", files.length === 0 && "text-gray-400")}>
                {getName()}
            </p>
            <Button variant="ghost" size="sm" disabled={files.length === 0}>
                <DownloadIcon className="w-3 h-3" />
            </Button>
        </div>
    );
}

type AIStatementProps = {
    text: string;
};

function AIStatement({ text }: AIStatementProps) {
    return <Markdown className="break-words max-w-md">{text}</Markdown>;
}

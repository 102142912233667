import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import React, { useState, useRef } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover.tsx";
import { useMutation } from "@tanstack/react-query";
import { base64ToBlob } from "./helper.js";
import { useApi } from "../../Api/useApi.ts";
import { useParams } from "react-router-dom";

const ScreenSettings = () => {
    const api = useApi();
    const { id } = useParams();
    const [error, setError] = useState("");
    const { welcomeScreen, setWelcomeScreen } = useFormEditorContext();

    const updateWelcomeScreen = (key, value) => {
        setWelcomeScreen({
            ...welcomeScreen,
            [key]: value,
        });
    };

    const updateAttachment = (key, value) => {
        /* set the value(base64) to the [key] */
        setWelcomeScreen({
            ...welcomeScreen,
            layout: {
                ...welcomeScreen?.layout,
                attachment: {
                    ...welcomeScreen?.layout?.attachment,
                    [key]: value,
                },
            },
        });

        if (value === null) return;
        /* uploading the image  */
        const mimeType = "image/png";
        const { blob, randomName } = base64ToBlob(value, mimeType);
        const formData = new FormData();
        formData.append("file", blob, randomName);
        uploadMutation.mutate(formData);
    };

    const uploadMutation = useMutation({
        mutationFn: (form) => api.files.uploadFile(form, id),
        onSuccess: (data) => {
            console.log("image uploaded successfully");
            const url = data?.data?.url;

            const updatedWelcomeScreen = {
                ...welcomeScreen,
                layout: {
                    ...welcomeScreen.layout,
                    attachment: {
                        ...welcomeScreen.layout.attachment,
                        href: url,
                    },
                },
            };

            setWelcomeScreen(updatedWelcomeScreen);

            const newFields = [...fields];
            const index = newFields.findIndex(
                (f) => f?.id === selectedField?.id,
            );
            newFields[index] = updatedWelcomeScreen;
            setFields(newFields);
        },
        onError: (error) => {
            console.error("Could not upload image", error);
        },
    });

    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(
        welcomeScreen?.layout?.attachment?.href ||
            welcomeScreen?.layout?.attachment?.link ||
            null,
    );
    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        /* check file max size  */
        if (file?.size > 7 * 1024 * 1024) {
            setError("File size should be less than 7MB");
            return;
        }

        setError("");

        if (file) {
            setImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
                updateAttachment("href", reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleRemoveImage = () => {
        setImage(null);
        setPreview(null);
        updateAttachment("href", null);
        fileInputRef.current.value = null;
    };

    const updatePlacement = (value) => {
        setWelcomeScreen({
            ...welcomeScreen,
            layout: {
                ...welcomeScreen.layout,
                placement: value,
            },
        });
    };

    return (
        <div
            className={`absolute right-4 top-7 flex gap-2 text-sm flex-col items-start`}
        >
            <Popover>
                <PopoverTrigger>
                    <div className="h-9 w-9 flex items-center justify-center  bg-[#F1F2F4] rounded-lg">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14 8.34928V13.016C14 13.3696 13.8595 13.7087 13.6095 13.9588C13.3594 14.2088 13.0203 14.3493 12.6667 14.3493H3.33333C2.97971 14.3493 2.64057 14.2088 2.39052 13.9588C2.14048 13.7087 2 13.3696 2 13.016V3.68262C2 3.329 2.14048 2.98986 2.39052 2.73981C2.64057 2.48976 2.97971 2.34928 3.33333 2.34928H8M10.6667 3.68262H14.6667M12.6667 1.68262V5.68262M14 10.3493L11.9427 8.29195C11.6926 8.04199 11.3536 7.90157 11 7.90157C10.6464 7.90157 10.3074 8.04199 10.0573 8.29195L4 14.3493M7.33333 6.34928C7.33333 7.08566 6.73638 7.68262 6 7.68262C5.26362 7.68262 4.66667 7.08566 4.66667 6.34928C4.66667 5.6129 5.26362 5.01595 6 5.01595C6.73638 5.01595 7.33333 5.6129 7.33333 6.34928Z"
                                stroke="#191B33"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align="end"
                    className=" relative flex justify-center w-60 p-3   bg-[#191B33] mt-1.5 rounded-xl"
                >
                    <div className="absolute -top-1.5 right-2 ">
                        <svg
                            width="18"
                            height="12"
                            viewBox="0 0 18 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.06241 0.781893C8.18972 -0.194391 9.86301 -0.194389 10.9903 0.781895L16.8001 5.81327C18.8998 7.63167 17.6138 11.0811 14.8361 11.0811H3.21663C0.438977 11.0811 -0.847034 7.63167 1.25268 5.81327L7.06241 0.781893Z"
                                fill="#191B33"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-col gap-4 ">
                        <div className="flex flex-col gap-1 ">
                            <div className="flex items-center gap-1 ">
                                <svg
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.25 7.5804V11.6637C12.25 11.9732 12.1271 12.2699 11.9083 12.4887C11.6895 12.7075 11.3928 12.8304 11.0833 12.8304H2.91667C2.60725 12.8304 2.3105 12.7075 2.09171 12.4887C1.87292 12.2699 1.75 11.9732 1.75 11.6637V3.49707C1.75 3.18765 1.87292 2.8909 2.09171 2.67211C2.3105 2.45332 2.60725 2.3304 2.91667 2.3304H7M9.33333 3.49707H12.8333M11.0833 1.74707V5.24707M12.25 9.3304L10.4498 7.53024C10.2311 7.31152 9.93436 7.18865 9.625 7.18865C9.31564 7.18865 9.01895 7.31152 8.80017 7.53024L3.5 12.8304M6.41667 5.8304C6.41667 6.47474 5.89433 6.99707 5.25 6.99707C4.60567 6.99707 4.08333 6.47474 4.08333 5.8304C4.08333 5.18607 4.60567 4.66374 5.25 4.66374C5.89433 4.66374 6.41667 5.18607 6.41667 5.8304Z"
                                        stroke="white"
                                        stroke-width="0.875"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <span className="font-medium text-white text-[0.875rem]">
                                    Add Image
                                </span>
                            </div>
                            <span className="font-normal hidden text-[12px] text-white ">
                                Dummy DescriptionText
                            </span>
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <div className="flex justify-between ">
                                <span className="text-white text-[12px] ">
                                    Upload Image
                                </span>
                                {preview && (
                                    <button
                                        className="text-[#F44336] text-xs font-medium "
                                        onClick={handleRemoveImage} // Optional: To remove the image preview
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                            {error && (
                                <span className="text-[#F44336] text-[12px]">
                                    {error}
                                </span>
                            )}
                            {preview && (
                                <div className="w-full ">
                                    <img
                                        src={preview}
                                        alt="Image Preview"
                                        className="object-contain border border-[#44546F]  h-[116px] w-[216px] rounded-lg "
                                    />
                                </div>
                            )}
                            {!preview && (
                                <button
                                    onClick={() => fileInputRef.current.click()}
                                    className=" w-full h-[116px]"
                                >
                                    <svg
                                        width="216"
                                        height="116"
                                        viewBox="0 0 216 117"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.581055"
                                            width="215"
                                            height="115"
                                            rx="5.5"
                                            fill="#172B4D"
                                        />
                                        <rect
                                            x="0.5"
                                            y="0.581055"
                                            width="215"
                                            height="115"
                                            rx="5.5"
                                            stroke="#44546F"
                                        />
                                        <path
                                            d="M114 60.0811V62.7477C114 63.1013 113.86 63.4405 113.609 63.6905C113.359 63.9406 113.02 64.0811 112.667 64.0811H103.333C102.98 64.0811 102.641 63.9406 102.391 63.6905C102.14 63.4405 102 63.1013 102 62.7477V60.0811M111.333 55.4144L108 52.0811M108 52.0811L104.667 55.4144M108 52.0811V60.0811"
                                            stroke="white"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>
                            )}
                            <div className="flex items-center justify-between h-full mt-1">
                                <span className=" text-white text-start  text-[12px] capitalize">
                                    image placement
                                </span>
                                <div className="flex items-center gap-2 ">
                                    <button
                                        className={`p-1 border h-9 w-9 rounded-md focus:outline-none ${
                                            welcomeScreen?.layout?.placement ===
                                            "left"
                                                ? " bg-white"
                                                : " bg-[#172B4D] border border-[#44546F]"
                                        }`}
                                        onClick={() => updatePlacement("left")}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="20"
                                            viewBox="0 0 28 20"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 2C0 0.895431 0.895431 0 2 0H13C13.5523 0 14 0.447716 14 1V19C14 19.5523 13.5523 20 13 20H2C0.895431 20 0 19.1046 0 18V2ZM17 11.75C17 11.3358 17.3358 11 17.75 11H22.25C22.6642 11 23 11.3358 23 11.75C23 12.1642 22.6642 12.5 22.25 12.5H17.75C17.3358 12.5 17 12.1642 17 11.75ZM17.75 8C17.3358 8 17 8.33579 17 8.75C17 9.16421 17.3358 9.5 17.75 9.5H24.25C24.6642 9.5 25 9.16421 25 8.75C25 8.33579 24.6642 8 24.25 8H17.75Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </button>
                                    <button
                                        className={`p-1 border h-9 w-9 rounded-md focus:outline-none ${
                                            welcomeScreen?.layout?.placement ===
                                            "right"
                                                ? " bg-white"
                                                : " bg-[#172B4D] border border-[#44546F]"
                                        }`}
                                        onClick={() => updatePlacement("right")}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="20"
                                            viewBox="0 0 28 20"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M14 1C14 0.447715 14.4477 0 15 0H26C27.1046 0 28 0.895431 28 2V18C28 19.1046 27.1046 20 26 20H15C14.4477 20 14 19.5523 14 19V1ZM3 11.75C3 11.3358 3.33579 11 3.75 11H8.25C8.66421 11 9 11.3358 9 11.75C9 12.1642 8.66421 12.5 8.25 12.5H3.75C3.33579 12.5 3 12.1642 3 11.75ZM3.75 8C3.33579 8 3 8.33579 3 8.75C3 9.16421 3.33579 9.5 3.75 9.5H10.25C10.6642 9.5 11 9.16421 11 8.75C11 8.33579 10.6642 8 10.25 8H3.75Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </PopoverContent>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    className="hidden"
                />
            </Popover>
        </div>
    );
};

export default ScreenSettings;

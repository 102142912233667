import React from "react";
import Center from "../Basic/Center";
import { MaxHeight } from "../Basic/MaxHeight";
import { Flex } from "antd";
import browserSignature from "browser-signature";
import { BarLoader } from "react-spinners";
import { useFormContext } from "../../Contexts/FormContext";
import { useApi } from "../../Api/useApi.ts";
import { useParams } from "react-router-dom";
import { FRONTEND_VERSION } from "../../Constants/index.js";
import { Image } from "antd";
import { useQuery } from "@tanstack/react-query";

const metaData = {
    user_agent: navigator.userAgent,
    platform: navigator.platform,
    referer: document.referrer,
    ip: "",
    browser: navigator.appName,
    client: navigator.appVersion,
    source: document.referrer,
    medium: document.referrer,
    domain_type: document.referrer,
    utm_id: document.referrer,
    utm_source: document.referrer,
    utm_medium: document.referrer,
    utm_campaign: document.referrer,
    utm_term: document.referrer,
    utm_content: document.referrer,
};

export const LoadingScreen = () => {
    const {
        isLoading,
        setData,
        setCurrentQuestionIndex,
        setIsInitialPage,
        setAcceptResponses,
        setFormNotFound,
    } = useFormContext();
    const api = useApi();
    const onNext = useNext(); // Get the `onNext` function

    //let { urlId: formUrlId } = useParams();
    let { urlId: formUrlId } = useParams();

    const { data, isFetching } = useQuery({
        queryKey: ["form", formUrlId], // Include formUrlId in the queryKey
        queryFn: async () => {
            try {
                const res = await api.forms.fetchFormData(formUrlId);

                // Handle different status codes
                if (res?.status === 410) {
                    setAcceptResponses(true);
                    return null;
                }

                if (res?.status === 404) {
                    setFormNotFound(true);
                    return null;
                }
                if (res?.status === 200) {
                    if (res?.data?.welcome_screens?.[0]) {
                        setData(res?.data);
                    } else {
                        setIsInitialPage(false);
                        setCurrentQuestionIndex(0);
                        setData(res?.data);
                        onNext(res?.data);
                    }
                }

                return res?.data;
            } catch (err) {
                console.error("Error fetching form data:", err);
                throw err;
            }
        },
        staleTime: Infinity,
        retryDelay: 1000,

        onError: (err) => {
            console.error("Error fetching form data:", err);
        },
    });

    if (isFetching) {
        return (
            <MaxHeight>
                <Center>
                    <Flex vertical gap={10}>
                        <Image
                            preview={false}
                            src={"/logo.png"}
                            width={"10vw"}
                        />
                        <div
                            style={{
                                opacity: isLoading ? 1 : 0,
                                width: "10vw",
                                borderRadius: "5px",
                                overflow: "hidden",
                            }}
                        >
                            <BarLoader
                                color={"rgb(30, 47, 105)"}
                                width={"10vw"}
                            />
                        </div>
                    </Flex>
                </Center>
            </MaxHeight>
        );
    }
};

export const useNext = () => {
    const {
        setSignature,
        next,
        setResponseId,
        data: asData,
    } = useFormContext();
    const api = useApi();

    return (data) => {
        // Accept data as an argument
        if (!data && !asData) {
            console.error("");
            return;
        }

        const signature = browserSignature();
        setSignature(signature);

        api.responses
            .startResponse({
                form_id: data?.id ? data?.id : asData?.id, // Ensure `data.id` exists
                signature,
                frontend_version: FRONTEND_VERSION,
                landed_at: new Date(),
                updated_at: new Date(),
                metadata: metaData, // Ensure metadata exists
            })
            .then((res) => {
                if (res?.data?.id) {
                    setResponseId(res?.data?.id);
                    next();
                } else {
                    console.error("useNext: Failed to retrieve response ID");
                }
            })
            .catch((err) => {
                console.error("useNext: API error", err);
            });
    };
};

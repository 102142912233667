import { createContext, useState, useContext, useEffect, useRef } from "react";
import useDetectScroll, { Direction } from "@smakss/react-scroll-direction";
import { useApi } from "../Api/useApi.ts";
import { getAnswersResponseListFromAnswersObject } from "../Utils/misc.js";
import { FRONTEND_VERSION } from "../Constants/index.js";
import { applyFont, loadFont } from "../Components/Form/DynamicFontLoader.jsx";

const FormContext = createContext();

export const FormProvider = ({ children, getDataFromLocal = false }) => {
    const [isLoading, setLoading] = useState(true);
    const [showNextButton, setShowNextButton] = useState(false);
    const [isInitialPage, setIsInitialPage] = useState(true); // Whether its the welcome page
    const [isEndPage, setEndPage] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [data, _setData] = useState();
    const [questions, setQuestions] = useState([]);
    const [responseId, setResponseId] = useState(null);
    const [signature, setSignature] = useState(null);
    const [formTheme, setFormTheme] = useState({});
    const [formSettings, setFormSettings] = useState(false);
    const [acceptResponses, setAcceptResponses] = useState(false);
    const [formNotFound, setFormNotFound] = useState(false);
    const [isFontLoaded, setIsFontLoaded] = useState(false);
    const [newFont, setNewFont] = useState(null);
    // const questions = useMemo(() => data?.fields | [], [data]);

    const api = useApi();

    /*  const setData = (data) => {
        _setData(data);
        setQuestions(data?.fields || []);
        setFormTheme(data?.theme);
        setFormSettings(data?.settings);
    }; */
    const setData = (data) => {
        if (JSON.stringify(data) !== JSON.stringify(_setData)) {
            _setData(data);
            setQuestions(data?.fields || []);
            setFormTheme(data?.theme);
            setFormSettings(data?.settings);
        }
    };

    useEffect(() => {
        const loadFonts = async () => {
            if (formTheme) {
                await Promise.all([
                    loadFont(formTheme?.font),
                    loadFont(formTheme?.font_family),
                ]);

                const newOne = applyFont(formTheme?.font_family);
                if (newOne !== newFont) {
                    setNewFont(newOne);
                }

                setIsFontLoaded(true);
                setLoading(false);
            }
        };

        loadFonts();
    }, []);

    function hexToRgb(hex) {
        hex = hex.replace(/^#/, "");
        let bigint;
        if (hex.length === 3) {
            bigint = parseInt(
                hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2],
                16,
            );
        } else if (hex.length === 6) {
            bigint = parseInt(hex, 16);
        } else {
            return null;
        }
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgb(${r}, ${g}, ${b})`;
    }

    function isRgbOrHex(value) {
        const hexPattern = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
        const rgbPattern = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
        if (hexPattern.test(value)) {
            return hexToRgb(value);
        } else if (rgbPattern.test(value)) {
            const rgbValues = value.match(rgbPattern).slice(1, 4).map(Number);
            if (rgbValues.every((val) => val >= 0 && val <= 255)) {
                return value;
            }
        }
        return "Invalid format";
    }

    const styles = {
        primary_color: isRgbOrHex(formTheme?.primary_color),
        secondary_color: isRgbOrHex(formTheme?.text_color),
        background_color: isRgbOrHex(formTheme?.background_color),
        button_color: isRgbOrHex(formTheme?.button_color),
        button_text_color: isRgbOrHex(formTheme?.button_text_color),
        answer_text_color: formTheme?.answer_text_color
            ? isRgbOrHex(formTheme?.answer_text_color)
            : isRgbOrHex(formTheme?.text_color),
        /*    primary_color: "rgb(208, 252, 3)",
    secondary_color: "rgb(143, 136, 231)", */
    };

    const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
    const [formInstance, setFormInstance] = useState(null);
    const [answers, setAnswers] = useState({});
    const answersRef = useRef({});

    const [isSubQuestion, setIsSubQuestion] = useState([false, 0, 0]); // [isSubQuestion, currentIndex, length]
    const [renderedQuestions, setRenderedQuestions] = useState([]);

    const { scrollDir, scrollPosition } = useDetectScroll();

    useEffect(() => {
        if (scrollDir === Direction.Up && scrollPosition > 0) {
            next();
        } else if (scrollDir === Direction.Down && scrollPosition > 0) {
            previous();
        }
    }, [scrollDir]);

    useEffect(() => {
        setShowNextButton(false);
        const subQuestions =
            questions[currentQuestionIndex]?.properties?.fields || [];
        const hasSubQuestions = subQuestions && subQuestions.length > 0;
        /*  console.log("CURRENT QUESTION INDEX CHANGED", {
      currentQuestionIndex,
      hasSubQuestions,
    }); */
        setIsSubQuestion([hasSubQuestions, 0, subQuestions.length]);
    }, [currentQuestionIndex]);

    const onValuesChanged = (changedValues) => {
        Object.keys(changedValues).forEach((field) => {
            answersRef.current[field] = changedValues[field];
        });
        setAnswers({ ...answersRef.current });
    };

    const updateAnswers = (field, mainField, answer) => {
        // const newAnswers = { ...answers };
        // if (mainField) {
        //   newAnswers[mainField][field] = answer;
        //   // if (newAnswers.hasOwnProperty(mainField)) {
        //   //     newAnswers[mainField][field] = answer;
        //   // }
        // } else {
        //   newAnswers[field] = answer;
        // }
        const newAnswers = { ...answers, [field]: answer };
        setAnswers(newAnswers);
    };

    useEffect(() => {
        if (!answers) {
            setAnswers({});
        }
    }, [answers]);

    const updateQuestion = () => {
        //console.log("updateQuestion called", currentQuestionIndex);
        setCurrentQuestion(questions[currentQuestionIndex]);
    };

    const previous = () => {
        if (currentQuestionIndex > 0) {
            setRenderedQuestions([
                ...renderedQuestions,
                questions[currentQuestionIndex]?.id,
            ]);
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            //    console.log("previous called", currentQuestionIndex);
            // setCurrentQuestion(questions[currentQuestionIndex - 1]);
        }
    };

    const next = () => {
        //  console.log("NEXT PRESSED", { isSubQuestion });
        if (isSubQuestion[0] && isSubQuestion[1] < isSubQuestion[2] - 1) {
            //   console.log("SUB QUESTION NEXT");
            setIsSubQuestion([true, isSubQuestion[1] + 1, isSubQuestion[2]]);
        } // setCurrentQuestionIndex(currentQuestionIndex + 1);fnjdksaf
        else {
            nextButtonPress();
        }
    };

    const nextButtonPress = () => {
        if (isInitialPage) {
            setIsInitialPage(false);
            setCurrentQuestionIndex(0);
        } else if (currentQuestionIndex < questions?.length) {
            formInstance
                ?.validateFields()
                .then((values) => {
                    //console.log({ values, answers });
                    setRenderedQuestions([
                        ...renderedQuestions,
                        questions[currentQuestionIndex]?.id,
                    ]);

                    // Merge the current values with existing answers
                    const newAnswers = { ...answers };
                    setAnswers(newAnswers);

                    // Generate answers list with correct field mapping
                    const answersResponseList =
                        getAnswersResponseListFromAnswersObject(
                            newAnswers,
                            questions,
                        );

                    if (currentQuestionIndex === questions?.length - 1) {
                        const redirectUrl =
                            data?.thankyou_screens?.[0]?.properties
                                ?.redirect_url;

                        if (
                            redirectUrl &&
                            redirectUrl !== "https://www.google.com"
                        ) {
                            //window.location.href = redirectUrl;
                            setEndPage(false);
                        } else {
                            setEndPage(true);
                        }
                    } else {
                        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    }
                    setIsSubQuestion([false, 0, 0]);

                    api.responses
                        .updateResponse(responseId, signature, {
                            answers: answersResponseList,
                            form_id: data?.id,
                            signature: signature,
                            landed_at: new Date(),
                            frontend_version: FRONTEND_VERSION,
                            type:
                                currentQuestionIndex === questions?.length - 1
                                    ? "completed"
                                    : "partial",
                        })
                        .then((res) => {
                            if (
                                currentQuestionIndex ===
                                questions?.length - 1
                            ) {
                                const redirectUrl =
                                    data?.thankyou_screens?.[0]?.properties
                                        ?.redirect_url;

                                if (
                                    redirectUrl &&
                                    redirectUrl !== "https://www.google.com"
                                ) {
                                    window.location.href = redirectUrl;
                                }
                            }
                        })
                        .catch((error) => {
                            console.log("error", error);
                        });
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    };

    const _setRenderedQuestions = (id) => {
        // setRenderedQuestions([...renderedQuestions, id]);
    };

    return (
        <FormContext.Provider
            value={{
                isLoading,
                setLoading,
                data,
                setData,
                next,
                nextButtonPress,
                currentQuestion,
                currentQuestionIndex,
                setCurrentQuestionIndex,
                updateQuestion,
                percentage: (currentQuestionIndex / questions.length) * 100,
                updateAnswers,
                isInitialPage,
                setIsInitialPage,
                formInstance,
                setFormInstance,
                previous,
                styles,
                answers,
                onValuesChanged,
                showNextButton,
                setShowNextButton,
                isSubQuestion,
                setIsSubQuestion,
                questions: data?.fields || [],
                isEndPage,
                setRenderedQuestions: _setRenderedQuestions,
                renderedQuestions,
                responseId,
                setResponseId,
                signature,
                setSignature,
                formTheme,
                setFormTheme,
                getDataFromLocal,
                formSettings,
                setFormSettings,
                acceptResponses,
                setAcceptResponses,
                formNotFound,
                setFormNotFound,
                newFont,
                setNewFont,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

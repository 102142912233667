import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { QUESTION_OBJECTS } from "../../Constants/questionTypes";

export const SiderFieldCard = ({ field, index, label = null }) => {
    const {
        selectedField,
        setSelectedField,
        fields,
        setFields,
        setWelcomeScreen,
        setIsWelcomeIsEnabled,
        setEndScreen,
        setIsEndIsEnabled,
        isWelcomeIsEnabled,
        isEndIsEnabled,
    } = useFormEditorContext();

    const isSelected =
        (selectedField?.id && selectedField?.id === field?.id) ||
        (selectedField === "welcome-screen" && field === "welcome-screen") ||
        (selectedField === "end-screen" && field === "end-screen");

    const removeField = (fieldId) => {
        const _index = fields.findIndex((f) => f?.id === fieldId);
        const updatedFields = fields.filter((f) => f?.id !== fieldId);
        setFields(updatedFields);
        const index =
            _index === 0
                ? isWelcomeIsEnabled
                    ? "welcome-screen"
                    : null
                : fields[_index - 1];
        setSelectedField(index);
    };

    const removeWelcomeScreen = () => {
        setWelcomeScreen({});
        setIsWelcomeIsEnabled(false);
        const index =
            fields.length > 0
                ? fields[0]
                : isEndIsEnabled
                ? "end-screen"
                : null;

        console.log("index", index);
        setSelectedField(index);
    };

    const removeEndScreen = () => {
        setEndScreen({});
        setIsEndIsEnabled(false);
        const index =
            fields?.length > 0
                ? fields[fields.length - 1]
                : isWelcomeIsEnabled
                ? "welcome-screen"
                : null;
        setSelectedField(index);
    };

    const removedUnderscore = (str) => {
        if (str === "section") return "Statement";
        return str.replace(/_/g, " ");
    };

    return (
        <div className="relative w-full max-w-[290px] group">
            <button
                className={`relative flex items-center w-full pl-0.5  transition duration-150 pr-6 rounded-lg cursor-pointer  hover:bg-[#F7F8F9] ${
                    isSelected
                        ? "bg-[#F1F2F4]"
                        : " border rounded-[8px] border-gray-100"
                }`}
                key={index}
            >
                <button className="pl-2 pr-1.5 h-8 ">
                    {field === "welcome-screen" || field === "end-screen" ? (
                        <div
                            className="flex  items-center   justify-center rounded-sm w-[42px] h-[20px] "
                            style={{
                                backgroundColor: isSelected
                                    ? "white"
                                    : "#F1F2F4",
                            }}
                        >
                            {field === "welcome-screen" ? (
                                <p className=" font-semibold capitalize text-[#091E42] text-[10px]">
                                    START
                                </p>
                            ) : (
                                <p className=" font-semibold capitalize text-[#091E42] text-[10px]">
                                    END
                                </p>
                            )}
                        </div>
                    ) : (
                        <FieldTypeIndicator
                            field={field}
                            index={index}
                            isSelected={isSelected}
                        />
                    )}
                </button>

                <button
                    className="text-xs text-start px-1.5 capitalize font-normal w-full text-[#191B33]  focus:outline-none py-3 truncate"
                    onClick={() => {
                        isSelected
                            ? setSelectedField(null)
                            : setSelectedField(field);
                    }}
                >
                    {field?.type === "ai_statement"
                        ? "AI Statement"
                        : field?.title
                        ? field?.title
                        : label
                        ? label
                        : removedUnderscore(field?.type)}
                </button>

                {/* For regular fields */}
                {field !== "end-screen" && field !== "welcome-screen" && (
                    <DeleteButton onDelete={() => removeField(field?.id)} />
                )}

                {field === "welcome-screen" && (
                    <DeleteButton
                        className={""}
                        onDelete={() => removeWelcomeScreen()}
                    />
                )}
                {field === "end-screen" && (
                    <DeleteButton
                        className={""}
                        onDelete={() => removeEndScreen()}
                    />
                )}
            </button>
        </div>
    );
};

const FieldTypeIndicator = ({ field, index, isSelected }) => {
    const fieldObject = QUESTION_OBJECTS[field?.type];
    const fieldIcon = fieldObject?.icon;
    const fieldColor = fieldObject?.color;
    const textColor = fieldObject?.textColor;

    return (
        <div
            className="flex items-center justify-center gap-1.5 rounded-sm w-[42px] h-[22px]  "
            style={{ backgroundColor: isSelected ? "white" : "#F1F2F4" }}
        >
            <span
                className="leading-4 text-[10px] font-semibold"
                style={{ textColor: "#091E42" }}
            >
                {index > 9 ? "" : "0"}
                {index}
            </span>

            <span className="flex items-center w-3 h-3 font-bold ">
                {fieldIcon}
            </span>
        </div>
    );
};

const DeleteButton = ({ onDelete, className }) => {
    return (
        <button
            className={`
        absolute z-50 flex items-center justify-center 
        w-5 h-5 p-1 transition-opacity duration-200 
        transform -translate-y-1/2 rounded-full 
        opacity-0 right-[12px] text-zinc-800 top-1/2 
        group-hover:opacity-100 hover:opacity-100 
        ${className}
      `}
            onClick={onDelete}
            aria-label="Delete"
        >
            <svg
                width="12"
                height="12"
                viewBox="0 0 7 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.5 1.34961L0.5 7.34961M0.5 1.34961L6.5 7.34961"
                    stroke="#44546F"
                    strokeWidth="0.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};

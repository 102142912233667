import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

import { Button } from '../ui/button.tsx';

import useStyles from '../../Hooks/useStyles.ts';

import { useFormEditorContext } from '../../Contexts/FormEditorContext.jsx';

import { Input } from '../ui/input.tsx';

type FormButtonProps = React.HTMLAttributes<HTMLInputElement> & {
    children?: React.ReactNode;
};

function FormButton({ children, ...props }: FormButtonProps) {
    const { getStyles } = useStyles();
    const {
        theme,
        goToNext,
        fields,
        selectedField,
        editorField,
        setEditorField,
        setFields,
        welcomeScreen,
        setWelcomeScreen,
    } = useFormEditorContext();

    const maxCharacters = 15;
    const minWidth = 65;
    const maxWidth = 200;

    const buttonValue =
        selectedField === 'welcome-screen'
            ? welcomeScreen?.button_text
            : editorField?.properties?.button_text || 'Next';

    const [buttonText, setButtonText] = useState(buttonValue);

    //  const Text = isLastField ? "Submit" : children;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [textWidth, setTextWidth] = useState<number>(minWidth);
    const textRef = useRef<HTMLSpanElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const updateTextWidth = () => {
        if (textRef.current) {
            requestAnimationFrame(() => {
                setTextWidth((prevWidth) => {
                    const newWidth = Math.min(
                        Math.max(textRef.current!.offsetWidth + 20, minWidth),
                        maxWidth,
                    );

                    return newWidth;
                });
            });
        }
    };

    useLayoutEffect(() => {
        updateTextWidth();
    }, [buttonText]);

    const handleClick = () => {
        if (isEditing) return;

        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
            return;
        }

        clickTimeout.current = setTimeout(() => {
            goToNext(); // Single click action: go to next
            clickTimeout.current = null;
        }, 300); // Delay to check if a double-click is triggered
    };

    const handleDoubleClick = () => {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
        setIsEditing(true);

        setTimeout(() => {
            inputRef.current?.focus();
        }, 100);
    };

    /*  const handleButtonChange = (e) => {
        if (selectedField === "welcome-screen") {
            setWelcomeScreen({
                ...welcomeScreen,
                button_text: e.target.value,
            });
        } else {
            const newFields = fields.map((field) => {
                if (field.id === selectedField.id) {
                    return {
                        ...field,
                        properties: {
                            ...field.properties,
                            button_text: e.target.value,
                        },
                    };
                }
                return field;
            });
            setFields(newFields);
            setEditorField({
                ...editorField,
                properties: {
                    ...editorField.properties,
                    button_text: e.target.value,
                },
            });
        }
    }; */
    const handleButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value.slice(0, maxCharacters); // Limit to 15 characters
        setButtonText(newValue);

        if (selectedField === 'welcome-screen') {
            setWelcomeScreen({ ...welcomeScreen, button_text: newValue });
        } else {
            const newFields = fields.map((field) =>
                field.id === selectedField.id
                    ? {
                          ...field,
                          properties: {
                              ...field.properties,
                              button_text: newValue,
                          },
                      }
                    : field,
            );
            setFields(newFields);
            setEditorField({
                ...editorField,
                properties: {
                    ...editorField.properties,
                    button_text: newValue,
                },
            });
        }
    };
    return (
        <Button
            style={{
                backgroundColor: theme?.button_color,
                color: theme?.button_text_color,
                borderRadius: theme?.rounded_corners,
                fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                fontWeight: theme?.is_bold === 'true' ? 'bold' : 'bold',
                width: `${textWidth}px`, // Dynamically set width
                height: getStyles('button', theme?.button_size).height,
            }}
            onClick={handleClick}
            className={props.className}
            onDoubleClick={handleDoubleClick}
        >
            {isEditing ? (
                <Input
                    ref={inputRef}
                    onBlur={() => setIsEditing(false)}
                    autoFocus
                    maxLength={maxCharacters}
                    style={{
                        color: theme?.button_text_color,
                        backgroundColor: theme?.button_color,
                        width: `${textWidth}px`, // Dynamically set width
                    }}
                    className="border-none focus:border-none focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                    onChange={(e) => {
                        handleButtonChange(e);
                    }}
                />
            ) : (
                <>
                    {/* Hidden span to calculate text width dynamically */}
                    <span
                        ref={textRef}
                        style={{
                            position: 'absolute',
                            visibility: 'hidden',
                            whiteSpace: 'nowrap',
                            fontFamily: theme?.font_family?.family,
                            fontWeight:
                                theme?.is_bold === 'true' ? 'bold' : 'normal',
                        }}
                    >
                        {buttonText}
                    </span>
                    {buttonText}
                </>
            )}
        </Button>
    );
}

export default FormButton;

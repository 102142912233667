export const MaxHeight = ({ children, editor }) => {
    return (
        <div
            style={{
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            {children}
        </div>
    );
};

import axios, { Axios, AxiosResponse } from "axios";
import { ENDPOINTS } from "../Constants/api.ts";
import { useAuth } from "@clerk/clerk-react";
import useFilters, { Filtering } from "./useFilters.ts";
import usePagination, { Pagination } from "./usePagination.ts";

const template = "custom_jwt_template";
interface startResponseData {
    form_id: string;
    signature: string;
    frontend_version: string;
    landed_at: Date;
    updated_at: Date;
    type: string;
    visit_response_id: string;
}

export const useApi = (): {
    forms: {
        fetchFormData: (id: string) => Promise<AxiosResponse<any, any>>;
        fetchFormList: (
            workspace_id: string,
        ) => Promise<AxiosResponse<any, any>>;
        createForm: (form: any) => Promise<AxiosResponse<any, any>>;
        deleteForm: (id: string) => Promise<AxiosResponse<any, any>>;
        updateForm: (id: string, form: any) => Promise<AxiosResponse<any, any>>;
        fetchFormResponse: (
            responseId: string,
        ) => Promise<AxiosResponse<any, any>>;
        fetchFormResponses: (
            id: string,
            pagination?: Pagination,
            filtering?: Filtering,
        ) => Promise<AxiosResponse<any, any>>;
        deleteFormResponse: (id: string) => Promise<AxiosResponse<any, any>>;
        deleteFormResponses: (
            formId: string,
            responseIds: string[],
        ) => Promise<AxiosResponse<any, any>>;
        exportResponses: (
            workspaceId: string,
            id?: string,
            responseIds?: string[],
        ) => Promise<AxiosResponse<any, any>>;
    };
    revisions: {
        createRevision: (
            form: any,
            theme: any,
            worksapce_id: string,
            form_id: string,
            is_published: boolean,
        ) => Promise<AxiosResponse<any, any>>;
        getAllRevisions: (
            workspace_id: string,
        ) => Promise<AxiosResponse<any, any>>;
        getOneRevision: (
            id: string,
            workspace_id: string,
            recordType?: string,
        ) => Promise<AxiosResponse<any, any>>;
        deleteOneRevision: (formId: string) => Promise<AxiosResponse<any, any>>;
    };
    aiForms: {
        createAiForms: (form: any) => Promise<AxiosResponse<any, any>>;
    };
    responses: {
        startResponse: (
            data: startResponseData,
        ) => Promise<AxiosResponse<any, any>>;
        updateResponse: (
            responseId: string,
            signature: string,
            answers: any[],
        ) => Promise<AxiosResponse<any, any>>;
        getResponses: (form_id: string) => Promise<AxiosResponse<any, any>>;
        uploadFile: (
            responseId: string,
            fieldId: string,
            file: any,
        ) => Promise<AxiosResponse<any, any>>;
        deleteResponses: (
            formId: string,
            responseIds: string[],
        ) => Promise<AxiosResponse<any, any>>;
    };
    files: {
        uploadFile: (
            file: any,
            form_id: any,
        ) => Promise<AxiosResponse<any, any>>;
        getFiles: (file_id: string) => Promise<AxiosResponse<any, any>>;
    };
    workspaces: {
        createWorkspace: (data: any) => Promise<AxiosResponse<any, any>>;
        getWorkspaces: () => Promise<AxiosResponse<any, any>>;
        updateWorkspace: (
            workspace_id: string,
            data: any,
        ) => Promise<AxiosResponse<any, any>>;
        deleteWorkspace: (
            workspace_id: string,
        ) => Promise<AxiosResponse<any, any>>;
    };
    utils: {
        getCountries: () => Promise<AxiosResponse<any, any>>;
    };

    themes: {
        createTheme: (data: any) => Promise<AxiosResponse<any, any>>;
        updateTheme: (
            theme_id: string,
            data: any,
        ) => Promise<AxiosResponse<any, any>>;
        deleteTheme: (theme_id: string) => Promise<AxiosResponse<any, any>>;
    };
    dashboard: {
        getStats: (
            workspace_id: string,
            time_interval: string,
        ) => Promise<AxiosResponse<any, any>>;
    };
} => {
    const { getToken } = useAuth();

    return {
        forms: {
            fetchFormData: async (
                id: string,
            ): Promise<AxiosResponse<any, any>> => {
                return axios.get(ENDPOINTS.forms.getOne(id)).catch((err) => {
                    console.error(err);
                    return err;
                });
            },

            fetchFormList: async (
                workspace_id: string,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.get(
                        ENDPOINTS.forms.getList(workspace_id),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            createForm: async (form: any): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.post(
                        ENDPOINTS.forms.createOne,
                        form,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error("Error creating form:", error);
                    throw error;
                }
            },
            deleteForm: async (
                id: string,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });
                return axios
                    .delete(ENDPOINTS.forms.deleteOne(id), {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },
            updateForm: async (
                id: string,
                form: any,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.put(
                        ENDPOINTS.forms.updateOne(id),
                        form,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            fetchFormResponse: async (responseId: string) => {
                try {
                    const token = await getToken({ template });
                    const response = axios.get(
                        ENDPOINTS.forms.getFormResponse(responseId),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            fetchFormResponses: async (
                id: string,
                pagination?: Pagination,
                filtering?: Filtering,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const { getFilters } = useFilters();
                    const filters = getFilters(filtering);
                    const { getPagination } = usePagination();
                    const _pagination = getPagination(pagination);

                    const queryParams = `${filters}&${_pagination}`;

                    const response = axios.get(
                        ENDPOINTS.forms.getFormResponses(id, queryParams),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    return err;
                }
            },
            deleteFormResponse: async (
                id: string,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = axios.delete(
                        ENDPOINTS.forms.deleteFormResponse(id),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            deleteFormResponses: async (
                formId: string,
                responseIds: string[],
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const queryParams = responseIds
                        .map((id) => `response_ids=${id}`)
                        .join("&");
                    const token = await getToken({ template });
                    const response = axios.delete(
                        ENDPOINTS.forms.deleteFormResponse(formId, queryParams),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            exportResponses: async (
                workspaceId: string,
                id?: string,
                responseIds?: string[],
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = axios.get(
                        ENDPOINTS.forms.exportFormResponses(
                            workspaceId,
                            id,
                            responseIds,
                        ),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                responseType: "blob",
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
        },
        revisions: {
            createRevision: async (
                form: any,
                workspaceId: string,
                theme: any,
                formId: string | null | undefined,
                isPublished: boolean | false,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    // Workspace ID is mandatory, so it must be included
                    const workspaceParam = `workspace_id=${workspaceId}`;

                    // Conditionally add form_id and is_published if they exist
                    const form_id = formId ? `&form_id=${formId}` : "";
                    const is_published =
                        isPublished !== undefined
                            ? `&is_published=${isPublished}`
                            : "&is_published=false";

                    // Construct the final query string
                    const queryParams = `${workspaceParam}${form_id}${is_published}`;

                    const revision_object = { form, theme };
                    const response = await axios.post(
                        ENDPOINTS.revisions.createOne(queryParams),
                        revision_object,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    return response;
                } catch (error) {
                    console.error("Error creating form:", error);
                    return error;
                }
            },
            getAllRevisions: async (
                workspace_id: string,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.get(
                        ENDPOINTS.revisions.getAll(workspace_id),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            getOneRevision: async (
                id: string,
                workspace_id: string,
                recordType?: string,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const record_type = recordType ? recordType : "form";
                    const response = await axios.get(
                        ENDPOINTS.revisions.getOne(
                            id,
                            workspace_id,
                            record_type,
                        ),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            deleteOneRevision: async (
                formId: string,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.delete(
                        ENDPOINTS.revisions.deleteOne(formId),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
        },
        aiForms: {
            createAiForms: async (
                form: any,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.post(
                        ENDPOINTS.aiForms.createOne,
                        form,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error("Error creating Ai-form:", error);
                    throw error;
                }
            },
        },
        responses: {
            startResponse: async (
                data: startResponseData,
            ): Promise<AxiosResponse<any, any>> => {
                return axios
                    .post(ENDPOINTS.responses.createOne(data?.form_id), data)
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },

            updateResponse: async (
                responseId: string,
                signature: string,
                answers: any[],
            ): Promise<AxiosResponse<any, any>> => {
                return axios
                    .put(
                        ENDPOINTS.responses.updateOne(responseId, signature),
                        answers,
                    )
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },

            getResponses: async (
                form_id: string,
            ): Promise<AxiosResponse<any, any>> => {
                return axios
                    .get(ENDPOINTS.responses.getResponses(form_id))
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },

            uploadFile: async (
                responseId: string,
                fieldId: string,
                file: any,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const formData = new FormData();
                    formData.append("file", file);

                    const response = await axios.post(
                        ENDPOINTS.responses.uploadFile(responseId, fieldId),
                        formData,
                    );

                    return response;
                } catch (err) {
                    console.error("Upload error:", err);
                    throw err; // Re-throw to be caught by the mutation error handler
                }
            },
            deleteResponses: async (
                formId: string,
                responseIds: string[],
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = await axios.delete(
                        ENDPOINTS.responses.deleteResponses(
                            formId,
                            responseIds,
                        ),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    return response;
                } catch (err) {
                    console.error("Upload error:", err);
                    throw err; // Re-throw to be caught by the mutation error handler
                }
            },
        },

        files: {
            uploadFile: async (
                file: any,
                form_id: any,
            ): Promise<AxiosResponse<any, any>> => {
                try {
                    const token = await getToken({ template });
                    const response = axios.post(
                        ENDPOINTS.files.upload(form_id),
                        file,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },
            getFiles: async (
                file_id: string,
            ): Promise<AxiosResponse<any, any>> => {
                return axios
                    .get(ENDPOINTS.files.getFiles(file_id))
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },
        },

        workspaces: {
            createWorkspace: async (
                data: any,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });
                try {
                    const response = axios.post(
                        ENDPOINTS.workspace.createOne,
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            getWorkspaces: async (): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });
                try {
                    const response = await axios.get(
                        ENDPOINTS.workspace.getList,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error("Error in getWorkspaces:", error); // Debugging error
                    throw error;
                }
            },

            updateWorkspace: async (
                workspace_id: string,
                data: any,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });
                try {
                    const response = axios.put(
                        ENDPOINTS.workspace.updateOne(workspace_id),
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },

            deleteWorkspace: async (
                workspace_id: string,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });

                return axios
                    .delete(ENDPOINTS.workspace.deleteOne(workspace_id), {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },
        },

        utils: {
            getCountries: async (): Promise<AxiosResponse<any, any>> => {
                try {
                    const response = await axios.get(
                        ENDPOINTS.utils.getCountries,
                    );
                    return response;
                } catch (err) {
                    console.error(err);
                    return err;
                }
            },
        },
        themes: {
            createTheme: async (
                theme: any,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });
                try {
                    const response = await axios.post(
                        ENDPOINTS.theme.createOne,
                        theme,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            updateTheme: async (
                theme_id: string,
                theme: any,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });

                try {
                    const response = axios.put(
                        ENDPOINTS.theme.updateAtheme(theme_id),
                        theme,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    return response;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            deleteTheme: async (
                theme_id: string,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });
                return axios
                    .delete(ENDPOINTS.theme.deleteAtheme(theme_id), {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },
        },
        dashboard: {
            getStats: async (
                workspace_id: string,
                time_interval: string,
            ): Promise<AxiosResponse<any, any>> => {
                const token = await getToken({ template });

                return axios
                    .get(
                        ENDPOINTS.Dashboard.getStats(
                            workspace_id,
                            time_interval,
                        ),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    )
                    .catch((err) => {
                        console.error(err);
                        return err;
                    });
            },
        },
    };
};

/* Dashboard.getStats(workspace_id, time_interval) */

import React from "react";
import { Checkbox as CBox, Typography } from "antd";
import { questionDescriptionStyle } from "../../Constants/styles";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useFormContext } from "../../Contexts/FormContext";
import { NEXT_QUESTION_KEYS } from "../../Constants/keys";

export const Checkbox = ({ field, onChange, value, ...props }) => {
    const { next } = useFormContext();

    return (
        <KeyboardEventHandler
            handleKeys={NEXT_QUESTION_KEYS}
            onKeyEvent={(key, e) => {
                //console.log(value);
                if (value) {
                    next();
                } else {
                    onChange(!value);
                }
            }}
        >
            <CBox
                style={{ borderColor: "red" }}
                autoFocus={true}
                checked={value}
                onChange={onChange}
                {...props}
            >
                <Typography.Text style={questionDescriptionStyle}>
                    {field?.properties?.checkbox_label ?? ""}
                </Typography.Text>
            </CBox>
        </KeyboardEventHandler>
    );
};

import React from 'react';
import LogoWithText from '../../assets/images/logoWithText.png';
import { motion } from 'framer-motion';
import Ounvin from '../../assets/images/ounvin.jpeg';
import cover from '../../assets/images/cover.svg';
import star from '../../assets/images/start-main-cover.svg';

const CommonLayout = ({ children }) => {
    return (
        <>
            <div className="grid items-center w-full h-screen grid-cols-1 overflow-hidden lg:grid-cols-2">
                {/* Left side image */}
                <div
                    className="flex-col hidden min-h-screen px-10 py-10 lg:flex "
                    style={{
                        backgroundImage: `url(${cover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {/* Navigation */}
                    <nav className="flex justify-start ">
                        <img
                            src={'/built-with-logo.png'}
                            className="w-[108.75px]"
                            alt="Logo"
                        />
                    </nav>

                    {/* Hero Section */}
                    <main className="relative flex flex-col justify-center h-full mt-auto ">
                        <div className="pr-[12%] relative ">
                            <div className="absolute  -top-6 right-10">
                                <img
                                    src={star}
                                    alt="star"
                                    className="h-12  w-11"
                                />
                            </div>
                            <h1 className="text-2xl md:text-4xl 3xl:text-7xl text-start  font-bold text-[#172B4D] mb-6">
                                Transform Form Responses Into Actionable
                                Insights Instantly 🚀
                            </h1>

                            <p className="text-lg md:text-xl 3xl:text-2xl text-start text-[#626F86] mb-12  pr-[17%] ">
                                Generate personalized AI statements from user
                                inputs to drive engaging, goal-focused
                                conversations.
                            </p>
                        </div>
                    </main>

                    {/* Testimonial */}
                    <div className="mt-auto">
                        <Testimonial
                            quote="AI statements effortlessly turns user inputs into clear, goal-focused insights. Super helpful for driving better conversations!"
                            author="Ounvin"
                            role="Metana Admissions Team"
                        />
                    </div>

                    {/* Background Decorations */}
                    <div className="fixed top-0 right-0 w-64 h-64 mt-auto transform translate-x-1/2 -translate-y-1/2 bg-purple-100 rounded-full opacity-50 blur-3xl -z-10" />
                    <div className="fixed bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2 rounded-full opacity-50 w-96 h-96 bg-lime-100 blur-3xl -z-10" />
                </div>

                {/* Right side content */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="relative z-10 flex flex-col items-center justify-center w-full px-4 bg-white lg:px-0"
                >
                    <div className="w-full max-w-[350px] lg:max-w-[400px]">
                        {children}
                    </div>
                </motion.div>
            </div>
        </>
    );
};

export default CommonLayout;

interface TestimonialProps {
    quote: string;
    author: string;
    role: string;
}

const Testimonial = ({ quote, author, role }: TestimonialProps) => {
    return (
        <div className=" bg-transparent backdrop-blur-sm rounded-2xl  border-2 border-[#FFFFFF] p-[30px] ">
            <p className="text-navy-900 font-medium  text-start md:text-[16px] 3xl:text-[24px] leading-relaxed mb-4">
                "{quote}"
            </p>
            <div className="flex items-center gap-4">
                <img
                    src={Ounvin}
                    alt="testimonial"
                    className="w-10 h-10 rounded-full  3xl:w-14 3xl:h-14"
                />
                <div>
                    <h4 className="font-medium text-start text-sm 3xl:text-lg text-[#172B4D]">
                        {author}
                    </h4>
                    <p className="text-[#44546F] text-sm 3xl:text-lg">{role}</p>
                </div>
            </div>
        </div>
    );
};

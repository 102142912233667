'use strict';
import React, { useState, useCallback } from 'react';
import tinyColor from 'tinycolor2';
import { useFormContext } from '../../Contexts/FormContext';
import { Button } from '../ui/button.tsx';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../Api/useApi.ts';
import { Plus } from 'lucide-react';
import clsx from 'clsx';
import { Loader, Image, Play, FileText } from 'lucide-react';
import { useQuestionContext } from '../../Contexts/QuestionContext.jsx';

export const FileUploadInput = ({ onChange, field, value, ...props }) => {
    const api = useApi();
    const { formTheme, responseId, formInstance } = useFormContext();
    const { uploading, setUploading, fileError, setFileError } =
        useQuestionContext();
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [file, setFile] = useState(null);
    const fieldId = field?.id as string;
    const allowedFileTypes = field?.properties?.allowed_file_types || [];
    const fileList = formInstance?.getFieldValue(field?.id) || [];
    const maxFileCount = field?.properties?.max_file_count || 2;

    // Map the file types to their corresponding MIME types
    /* const fileTypeMapping = {
        image: 'image/*',
        video: 'video/*',
        audio: 'audio/*',
        document: 'application/*',
        text: 'text/*',
    }; */
    const fileTypeMapping = {
        image: 'image/jpeg, image/png, image/gif, image/bmp, image/tiff, image/webp',
        video: 'video/mp4, video/x-msvideo, video/quicktime, video/x-ms-wmv, video/x-flv, video/x-matroska, video/webm',
        audio: 'audio/mpeg, audio/wav, audio/flac, audio/aac, audio/ogg, audio/x-ms-wma, audio/mp4',
        document:
            'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/zip',
        text: 'text/plain',
    };

    // Generate the accept string
    const acceptString = allowedFileTypes
        .map((type) => fileTypeMapping[type]) // Map to MIME types
        .filter(Boolean) // Remove undefined mappings
        .join(','); // Join with commas

    const { mutate: uploadFileMutation, isPending } = useMutation({
        mutationFn: (file) =>
            api.responses.uploadFile(responseId, fieldId, file),
        onSuccess: (data) => {
            setFile(null);
            setUploading(false);
            const existingFiles = formInstance?.getFieldValue(field?.id) || [];
            onChange([...existingFiles, data?.data]);
        },
        onError: (error) => {
            setUploading(false);
            setFileError('An error occurred while uploading the file.');
            console.error('File upload error:', error);
        },
    });

    const handleDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const maxFileSizes = {
        image: 10 * 1024 * 1024, // 10 MB
        video: 100 * 1024 * 1024, // 100 MB
        audio: 50 * 1024 * 1024, // 50 MB
        application: 20 * 1024 * 1024, // 20 MB
        text: 10 * 1024 * 1024, // 10 MB
    };

    const validateFileSize = (file: any) => {
        const type = file?.type?.split('/')[0]; // Get the general type (e.g., "image")
        const maxSize = maxFileSizes[type];
        if (!maxSize) {
            return true; // Allow file if type is not in maxFileSizes
        }

        return file?.size <= maxSize; // Return true if size is valid
    };

    const handleFileChange = (e: any) => {
        e.preventDefault();
        setFileError(''); // Clear previous error
        const files = e?.target?.files || e?.dataTransfer?.files;
        if (files && files.length > 0) {
            const validFiles = [];
            const filteredFiles = Array.from(files).filter((file) =>
                validateFileSize(file),
            );

            if (filteredFiles.length === 0) {
                setFileError(
                    'One or more files exceed the maximum file size limit.',
                );
                return;
            }

            if ((fileList?.length || 0) + filteredFiles.length > maxFileCount) {
                setFileError(
                    `You can only upload up to ${maxFileCount} files.`,
                );
                return;
            }

            filteredFiles.forEach((file) => {
                validFiles.push(file);
                setUploading(true);
                uploadFileMutation(file);
            });

            setFile(validFiles);
            setFileError(''); // Clear previous error
        }
    };

    console.log('file error', fileError);

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
        handleFileChange(e);
    }, []);

    // Add function to handle file deletion
    const handleFileDelete = (fileId: string) => {
        const existingFiles = formInstance?.getFieldValue(field?.id) || [];
        const updatedFiles = existingFiles.filter(
            (file) => file?.id !== fileId,
        );
        onChange(updatedFiles);
    };

    return (
        <div className="flex items-center justify-center w-full">
            <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-start w-full min-h-[136px]  rounded-lg cursor-pointer  "
                style={{
                    backgroundColor: tinyColor(formTheme?.button_color)
                        .setAlpha(0.01)
                        .toRgbString(),
                    borderColor: formTheme?.button_color,
                    borderWidth: '1px',
                    borderStyle: 'dashed',
                    padding: '1rem',
                    opacity: isDragging ? 0.2 : 1,
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {fileList?.length === 0 ? (
                    <>
                        {uploading ? (
                            <UploadedFileCard
                                file={file}
                                uploading={uploading}
                                theme={formTheme}
                            />
                        ) : (
                            <>
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                        className="w-8 h-8 mb-4 "
                                        style={{ color: formTheme?.text_color }}
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 16"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                    </svg>
                                    <p
                                        className="mb-2 text-[1rem] font-medium "
                                        style={{ color: formTheme?.text_color }}
                                    >
                                        Choose file or drag and drop
                                    </p>
                                    <p
                                        className="flex flex-col text-xs font-normal "
                                        style={{
                                            color: tinyColor(
                                                formTheme?.text_color,
                                            )
                                                .setAlpha(0.5)
                                                .toRgbString(),
                                        }}
                                    >
                                        <span>Size limit (10MB max)</span>
                                        <span>
                                            Max file count: {maxFileCount}
                                        </span>
                                    </p>
                                </div>
                                <input
                                    name={field?.id}
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    style={{ display: 'none' }}
                                    accept={acceptString} // Dynamically set accept attribute
                                    onChange={handleFileChange}
                                    multiple
                                    maxLength={maxFileCount}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center w-full space-y-2">
                        {uploading && (
                            <UploadedFileCard
                                file={file}
                                uploading={uploading}
                                theme={formTheme}
                            />
                        )}
                        {fileList?.map((file) => (
                            <UploadedFileCard
                                key={file.id}
                                file={file}
                                theme={formTheme}
                                onDelete={() => handleFileDelete(file?.id)}
                            />
                        ))}
                        <AddFileButton
                            onClick={handleFileChange}
                            field={field}
                            acceptString={acceptString}
                            count={fileList?.length}
                            maxFileCount={maxFileCount}
                        />
                    </div>
                )}
                {fileError && (
                    <p className="mt-2 text-xs text-red-500">{fileError}</p>
                )}
            </label>
        </div>
    );
};

type UploadedFileCardProps = {
    file: any;
    uploading?: boolean;
    theme: any;
    onDelete?: (id?: string | undefined) => void;
};

const UploadedFileCard = ({
    file,
    uploading,
    theme,
    onDelete,
}: UploadedFileCardProps) => {
    const formatFileSize = (sizeInBytes: number): string => {
        const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        let size = sizeInBytes;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${size?.toFixed(2)} ${units[unitIndex]}`;
    };

    return (
        <div
            className={clsx(
                ' w-full p-2 rounded-[8px] bg-gray-100',
                'bg-gray-100 h-14',
            )}
        >
            <div className="flex items-center justify-between ">
                {/* icon and the name */}
                <div className="flex items-center space-x-2">
                    <div className="flex items-center justify-center w-10 h-10 p-2 bg-white rounded-[8px] ">
                        {uploading ? (
                            <Loader
                                size={20}
                                color={tinyColor(theme?.button_color)
                                    .setAlpha(0.2)
                                    .toRgbString()}
                                className=" animate-spin"
                            />
                        ) : file?.file_type === 'image' ? (
                            <Image size={20} color={'#2563EB'} />
                        ) : file?.file_type === 'video' ? (
                            <Play size={20} color={'#2563EB'} />
                        ) : file?.file_type === 'application' ||
                          file?.file_type === 'text' ? (
                            <FileText size={20} color={'#2563EB'} />
                        ) : (
                            <Image size={20} color={'#2563EB'} />
                        )}
                    </div>
                    <p className="text-[1rem] font-normal truncate max-w-[100px] md:max-w-[300px]">
                        {file?.file_name || 'Untitled'}
                    </p>
                </div>
                {/* size and the delete button */}
                <div className="flex items-center space-x-2">
                    <p className="text-xs font-normal">
                        {file?.file_size
                            ? formatFileSize(file?.file_size)
                            : 'calculating...'}
                    </p>
                    <Button
                        variant="ghost"
                        type="button"
                        onClick={() => {
                            onDelete?.(file?.id);
                        }}
                        className="p-3 bg-[#f443360d] rounded-md hover:bg-[#f443360d]"
                    >
                        <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2 4.3151H14M12.6667 4.3151V13.6484C12.6667 14.3151 12 14.9818 11.3333 14.9818H4.66667C4 14.9818 3.33333 14.3151 3.33333 13.6484V4.3151M5.33333 4.3151V2.98177C5.33333 2.3151 6 1.64844 6.66667 1.64844H9.33333C10 1.64844 10.6667 2.3151 10.6667 2.98177V4.3151"
                                stroke="#F44336"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </Button>
                </div>
            </div>
        </div>
    );
};

type AddFileButtonProps = {
    count: number;
    onClick: (e: any) => void;
    field: any;
    acceptString: string;
    maxFileCount: number;
};
const AddFileButton = ({
    count,
    onClick,
    field,
    acceptString,
    maxFileCount,
}: AddFileButtonProps) => {
    return (
        <>
            <div className=" w-full h-10 px-4 py-2 items-center flex justify-between bg-[#2563eb0d] rounded-[8px]">
                {/* item count  */}
                <div className="flex items-center space-x-2 ">
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.3334 4.31445L6.00008 11.6478L2.66675 8.31445"
                            stroke="#2563EB"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <p className=" text-[#2563eb] text-sm">{count} item</p>
                </div>
                {/* item count and add button */}
                <div className="flex items-center space-x-2 ">
                    <p className=" text-[#2563eb] text-sm">{count} item</p>
                    <Button
                        variant="ghost"
                        type="button"
                        className=" p-0  h-6 w-6 bg-[#2563eb]  hover:bg-[#2563eb] rounded-full "
                        onClick={() =>
                            document.getElementById('dropzone-file')?.click()
                        }
                    >
                        <Plus size={16} className="text-white " />
                    </Button>
                    <input
                        name={field?.id}
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        style={{ display: 'none' }}
                        accept={acceptString} // Dynamically set accept attribute
                        onChange={onClick}
                        multiple
                        maxLength={maxFileCount}
                    />
                </div>
            </div>
        </>
    );
};

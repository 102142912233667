import React from "react";

import { createContext, useState, useContext } from "react";

const FormSubmissionsContext = createContext<{
    openSidebar: boolean;
    setOpenSidebar: (value: boolean) => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    form: any;
    setForm: React.Dispatch<React.SetStateAction<any>>;
    fields: any[];
    setFields: React.Dispatch<React.SetStateAction<any[]>>;
    totalResponses: number;
    setTotalResponses: React.Dispatch<React.SetStateAction<number>>;
    responses: any[];
    setResponses: React.Dispatch<React.SetStateAction<any[]>>;
    selection: Selection;
    setSelection: React.Dispatch<React.SetStateAction<Selection>>;
    selectedFields: any[];
    setSelectedFields: React.Dispatch<React.SetStateAction<any[]>>;
    activeRow: any;
    setActiveRow: React.Dispatch<React.SetStateAction<any>>;
}>({
    openSidebar: true,
    setOpenSidebar: () => {},
    loading: false,
    setLoading: () => {},
    form: null,
    setForm: () => {},
    fields: [],
    setFields: () => {},
    totalResponses: 0,
    setTotalResponses: () => {},
    responses: [],
    setResponses: () => {},
    selection: "none",
    setSelection: () => {},
    selectedFields: [],
    setSelectedFields: () => {},
    activeRow: null,
    setActiveRow: () => {},
});

type Field = {
    id: string;
    title: string;
    type: string;
    checked: boolean;
};

type Selection = "all" | "none";

export const FormSubmissionsProvider = ({ children }) => {
    const [openSidebar, setOpenSidebar] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<any>(null);
    const [fields, setFields] = useState<Field[]>([]);
    const [totalResponses, setTotalResponses] = useState(0);
    const [responses, setResponses] = useState<any[]>([]);
    const [selection, setSelection] = useState<Selection>("none");
    const [selectedFields, setSelectedFields] = useState<any[]>([]);
    const [activeRow, setActiveRow] = useState<any>(null);

    // TODO: Add responses fetching here :-)
    // So that the selection can be handled using only the selectedFields state

    return (
        <FormSubmissionsContext.Provider
            value={{
                openSidebar,
                setOpenSidebar,
                loading,
                setLoading,
                form,
                setForm,
                fields,
                setFields,
                totalResponses,
                setTotalResponses,
                responses,
                setResponses,
                selection,
                setSelection,
                selectedFields,
                setSelectedFields,
                activeRow,
                setActiveRow,
            }}
        >
            {children}
        </FormSubmissionsContext.Provider>
    );
};

export const useFormSubmissionsContext = () => {
    const context = useContext(FormSubmissionsContext);
    if (!context) {
        throw new Error(
            "useFormSubmissionsContext must be used within a FormProvider",
        );
    }
    return context;
};

import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { QuestionView } from "./QuestionView.tsx";
import {
    QuestionProvider,
    useQuestionContext,
} from "../Contexts/QuestionContext";
import { QuestionBackground } from "../Components/Form/QuestionBackground.tsx";
import { Col, ConfigProvider, Row, Grid } from "antd";

import { WelcomeScreen } from "../Components/Form/WelcomeScreen.jsx";
import { FormHeader } from "../Components/Form/FormHeader.jsx";
import { FormFooter } from "../Components/Form/FormFooter.jsx";
import { fadeAnimation, questionAnimation } from "../Constants/animations.js";
import tinycolor from "tinycolor2";
import {
    answerCheckboxInputTextSize,
    answerInputTextSize,
    buttonTextSize,
    selectOptionSize,
} from "../Constants/styles.js";
import { LoadingScreen } from "../Components/Form/LoadingScreen.jsx";
import { useFormContext } from "../Contexts/FormContext.jsx";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { EndScreen } from "../Components/Form/EndScreen.jsx";
import { NEXT_QUESTION_KEYS } from "../Constants/keys.js";
import { AttachmentLayout } from "../Components/Form/AttachmentLayout.jsx";
import { SwipeDetector } from "../Components/Basic/SwipeDetector.jsx";
import { ROUTES } from "../Constants/routes.js";
import formnotfoundCover from "../assets/images/Form_not_found_cover.png";
import notAvailable from "../assets/images/notAvailable.png";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

export const FormView = () => {
    const {
        isLoading,
        updateQuestion,
        currentQuestionIndex,
        isInitialPage,
        next,
        styles,
        questions,
        isEndPage,
        nextButtonPress,
        previous,
        showNextButton,
        formTheme,
        acceptResponses,
        formNotFound,
        newFont,
        formSettings,
    } = useFormContext();
    const constTheme = formTheme;

    const dynamicWidth = "100vw";

    const primary_color = styles.primary_color;
    const secondary_color = styles.secondary_color;
    const buttonTextColor = "#000";
    const textColor = secondary_color;

    const nextPressed = () => {
        if (showNextButton) {
            next();
        } else {
            console.log("next button is false ");
        }
    };

    const noQuestions = questions?.length === 0;

    if (
        formSettings?.analytics &&
        formSettings?.analytics?.google_tag_manager_id
    ) {
        useEffect(() => {
            const tagManagerArgs = {
                gtmId: formSettings?.analytics?.google_tag_manager_id,
            };
            TagManager.initialize(tagManagerArgs);
        }, []);
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primary_color,
                    fontFamily: newFont || "Poppins",
                    colorTextQuaternary: tinycolor(secondary_color)
                        .lighten(25)
                        .toString(),
                    borderRadius: 4,
                },

                components: {
                    Button: {
                        contentFontSizeLG: buttonTextSize,
                        fontWeight: "bold",
                        primaryColor: constTheme?.button_text_color,
                        defaultActiveColor: buttonTextColor,
                        defaultBg: constTheme?.button_color,
                        defaultHoverColor: constTheme?.button_text_color,
                        defaultHoverBg: tinycolor(constTheme?.button_color)
                            .lighten(10)
                            .toString(),
                        defaultHoverBorderColor: tinycolor(
                            constTheme?.button_color,
                        )
                            .lighten(10)
                            .toString(),
                        defaultColor: constTheme?.button_text_color,
                        defaultActiveBorderColor: constTheme?.button_color,
                    },
                    Select: {
                        optionFontSize: selectOptionSize,
                        optionSelectedBg: "transparent",
                        paddingContentHorizontalLG: 0,
                        showArrowPaddingInlineEnd: 0,
                    },
                    Input: {
                        inputFontSizeSM: answerCheckboxInputTextSize,
                        inputFontSizeLG: answerInputTextSize,
                        colorTextPlaceholder: tinycolor(secondary_color)
                            ?.setAlpha(0.2)
                            .toString(),
                        paddingInlineSM: 0,
                        paddingInlineLG: 0,
                        colorText: textColor,
                        fontWeightStrong: 800,
                        colorBorder: textColor,
                    },
                    Checkbox: {
                        colorBorder: secondary_color,
                        colorPrimary: secondary_color,
                        colorBgTextActive: secondary_color,
                    },
                },
            }}
        >
            <KeyboardEventHandler
                handleKeys={NEXT_QUESTION_KEYS}
                onKeyEvent={(key, e) => {
                    //  console.log("FormView enter/tab key pressed");
                    nextPressed();
                    // nextButtonPress()
                }}
            />
            <QuestionBackground>
                {formNotFound ? (
                    <FormNotFound />
                ) : acceptResponses ? (
                    <FormNotAvailable />
                ) : isLoading ? (
                    <motion.div key="loading-screen" {...fadeAnimation}>
                        <LoadingScreen />
                    </motion.div>
                ) : isInitialPage ? (
                    <motion.div key="welcome-screen">
                        <WelcomeScreen />
                        {/*  <FormFooter showControls={false} /> */}
                    </motion.div>
                ) : isEndPage || noQuestions ? (
                    <motion.div
                        // {...elementAnimation}
                        style={{
                            overflow: "hidden",
                        }}
                        key={"end-screen"}
                    >
                        {/*    <FormHeader /> */}
                        <EndScreen />
                    </motion.div>
                ) : (
                    <motion.div
                        key={"form-123213233243214"}
                        /*  {...questionAnimation} */
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            //overflowY: 'scroll', // Force scrollbar to stay visible
                            overflowY: "auto",
                            overflowX: "hidden",
                            backgroundColor: formTheme?.background_color,
                            height: "100vh", // Ensure this container also has a consistent height
                        }}
                        className="no-scrollbar"
                    >
                        <FormHeader />
                        <AnimatePresence
                            //key={"fdjsonf"}
                            mode="wait"
                            onExitComplete={updateQuestion}
                        >
                            {questions[currentQuestionIndex]?.id && (
                                <motion.div
                                    key={`question-${questions[currentQuestionIndex]?.id}-${currentQuestionIndex}`}
                                    {...questionAnimation}
                                >
                                    <QuestionProvider
                                        question={
                                            questions[currentQuestionIndex]
                                        }
                                    >
                                        <QuestionPageLayout
                                            dynamicWidth={dynamicWidth}
                                        />
                                    </QuestionProvider>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <FormFooter />
                    </motion.div>
                )}
            </QuestionBackground>
        </ConfigProvider>
    );
};

export const QuestionPageLayout = ({ dynamicWidth }) => {
    const { currentQuestion } = useQuestionContext();
    const { useBreakpoint } = Grid;
    const { xs, sm, md, lg, xl } = useBreakpoint();

    //const hasAttachment = !!currentQuestion?.attachment?.link;
    const hasAttachment = currentQuestion?.attachment?.link;
    //console.log("hasAttahement", hasAttachment);

    const attachmentPlacement =
        (hasAttachment && currentQuestion?.attachment?.placement) || "right";

    // console.log({ hasAttachment, attachmentPlacement });

    return (
        <>
            <div className="block md:hidden">
                <div className="flex flex-col gap-2">
                    {!hasAttachment && <QuestionView />}

                    {hasAttachment && attachmentPlacement == "left" && (
                        <>
                            <AttachmentLayout
                                attachment={currentQuestion.attachment}
                            />
                            <QuestionView />
                        </>
                    )}

                    {hasAttachment && attachmentPlacement == "right" && (
                        <>
                            <QuestionView />
                            <AttachmentLayout
                                attachment={currentQuestion.attachment}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="hidden md:flex">
                <Row style={{ width: dynamicWidth }}>
                    {hasAttachment && attachmentPlacement == "left" && (
                        <Col
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            lg={24}
                            md={24}
                            sm={24}
                        >
                            <AttachmentLayout
                                attachment={currentQuestion.attachment}
                            />
                        </Col>
                    )}
                    <Col
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flex: 1,
                        }}
                        lg={24}
                        md={24}
                        sm={24}
                    >
                        <QuestionView />
                    </Col>
                    {hasAttachment && attachmentPlacement == "right" && (
                        <Col
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: "70%",
                            }}
                            lg={24}
                            md={24}
                            sm={24}
                        >
                            <AttachmentLayout
                                attachment={currentQuestion.attachment}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
};

const FormNotFound = () => {
    return (
        <div
            className="relative flex items-center justify-center w-full h-screen "
            style={{
                backgroundImage: `url(${formnotfoundCover})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
            }}
        >
            {/* branding logo */}
            <div className="absolute top-4 left-4">
                <img
                    src={"/built-with-logo.png"}
                    className="w-[108.75px]"
                    alt="Logo"
                />
            </div>
            <div className="space-y-6 text-center">
                <h1 className=" text-2xl font-bold text-[#191B33] md:text-[40px] leading-none ">
                    Lost in Form Space
                </h1>
                <p className="mb-8 text-xl text-[#191B33] md:text-2xl">
                    The form you’re looking for doesn’t exist
                    <br />
                    or may have been removed.
                </p>
                <button
                    onClick={() => {
                        window.location.href = `${ROUTES.DASHBOARD}`;
                    }}
                    className="px-4 py-2 font-medium text-white transition-colors bg-[#2563EB] rounded-full "
                >
                    Build your own Buildform
                </button>
            </div>
        </div>
    );
};

const FormNotAvailable = () => {
    return (
        <div className="relative flex items-center justify-center w-full h-screen ">
            {/* branding logo */}
            <div className="absolute top-4 left-4">
                <img
                    src={"/built-with-logo.png"}
                    className="w-[108.75px]"
                    alt="Logo"
                />
            </div>
            <div className="grid items-center w-full grid-cols-1 lg:grid-cols-2 mx-auto lg:px-[5%] ">
                <div className="">
                    <img
                        src={notAvailable}
                        alt="Not Available"
                        className=" max-w-[497px] max-h-[400px]"
                    />
                </div>

                <div className="space-y-6 text-center lg:text-start">
                    <h1 className=" text-2xl font-bold text-[#191B33] md:text-[40px] leading-none ">
                        This Form is on Vacation
                    </h1>
                    <p className="mb-8 text-xl text-[#191B33] md:text-2xl">
                        This form is currently not accepting
                        <br />
                        responses. Thank you for your time!
                    </p>
                    <button
                        onClick={() => {
                            window.location.href = `${ROUTES.DASHBOARD}`;
                        }}
                        className="px-4 py-2 font-medium text-white transition-colors bg-[#2563EB] rounded-full "
                    >
                        Build your own Buildform
                    </button>
                </div>
            </div>
        </div>
    );
};
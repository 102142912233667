import React from "react";

import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";
import { Switch } from "../../ui/switch.tsx";
import { Label } from "../../ui/label.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { cn } from "../../../lib/utils.js";

import { CloudUploadIcon } from "lucide-react";

const DefaultAllowedTypes = ["image", "audio", "video", "document"];

function FileUpload() {
    const { getStyles } = useStyles();
    const { editorField, setEditorField, theme, setFields, device } =
        useFormEditorContext();

    const updateField = (fieldId, key, subkey, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    const handleOnAllowedTypesChange = (types: string[]) => {
        if (types.length === 0)
            updateField(editorField?.id, "properties", "allowed_file_types", [
                DefaultAllowedTypes[0],
            ]);
        else
            updateField(
                editorField?.id,
                "properties",
                "allowed_file_types",
                types,
            );
    };

    return (
        <div className="flex flex-col w-full max-w-[606px]">
            <ResizableTextarea
                placeholder="Add question title"
                value={editorField?.title || ""}
                onChange={(value) =>
                    updateField(editorField?.id, "title", null, value)
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h2", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h2", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            />
            <ResizableTextarea
                placeholder="Add question description (Optional)"
                value={editorField?.properties?.description || ""}
                onChange={(value) =>
                    updateField(
                        editorField?.id,
                        "properties",
                        "description",
                        value,
                    )
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h4", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h4", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="mt-2"
            />
            <DragArea className="mt-6" />
            <FileUploadConfig
                className="mt-2"
                allowedTypes={
                    editorField?.properties?.allowed_file_types ||
                    DefaultAllowedTypes
                }
                onAllowedTypesChange={handleOnAllowedTypesChange}
            />
            <FormButton className="mt-3">Next</FormButton>
        </div>
    );
}

export default FileUpload;

type DragAreaProps = React.HTMLAttributes<HTMLDivElement>;

function DragArea({ className, ...props }: DragAreaProps) {
    return (
        <div
            className={cn(
                "rounded-[16px] p-4 bg-[#FAF5FF] border border-dashed border-[#805AD5] py-2 h-[160px] flex flex-col justify-center items-center",
                className,
            )}
            {...props}
        >
            <div className="text-center">
                <CloudUploadIcon className="w-6 h-6 text-[#191B33] mx-auto" />
                <p className="mt-4 font-medium text-md text-gray-950">
                    Choose file or drag and drop
                </p>
                <p className="mt-1 text-xs text-gray-600">
                    Size limit (10MB max)
                </p>
            </div>
        </div>
    );
}

type FileUploadConfigProps = React.HTMLAttributes<HTMLDivElement> & {
    allowedTypes: string[];
    onAllowedTypesChange: (types: string[]) => void;
};

function FileUploadConfig({
    className,
    allowedTypes,
    onAllowedTypesChange,
    ...props
}: FileUploadConfigProps) {
    const handleOnAllowedTypesChange = (type: string) => {
        if (allowedTypes.includes(type)) {
            onAllowedTypesChange(allowedTypes.filter((t) => t !== type));
        } else {
            onAllowedTypesChange([...allowedTypes, type]);
        }
    };

    return (
        <div className={cn("flex flex-col gap-2", className)} {...props}>
            <p className="text-xs text-left text-gray-600">
                Select supported file types. Visible only in editor!
            </p>
            <div className="flex flex-row flex-wrap gap-2">
                <ModifiedSwitch
                    label="Images"
                    checked={allowedTypes.includes("image")}
                    onChange={() => handleOnAllowedTypesChange("image")}
                />
                <ModifiedSwitch
                    label="Audio"
                    checked={allowedTypes.includes("audio")}
                    onChange={() => handleOnAllowedTypesChange("audio")}
                />
                <ModifiedSwitch
                    label="Videos"
                    checked={allowedTypes.includes("video")}
                    onChange={() => handleOnAllowedTypesChange("video")}
                />
                <ModifiedSwitch
                    label="Documents"
                    checked={allowedTypes.includes("document")}
                    onChange={() => handleOnAllowedTypesChange("document")}
                />
            </div>
        </div>
    );
}

type ModifiedSwitchProps = {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

function ModifiedSwitch({ label, checked, onChange }: ModifiedSwitchProps) {
    return (
        <div className="bg-gray-100 rounded-[8px] px-3 py-2 gap-2 flex items-center w-fit">
            <Label htmlFor={label} className="text-xs">
                {label}
            </Label>
            <Switch id={label} checked={checked} onCheckedChange={onChange} />
        </div>
    );
}

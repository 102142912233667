import React from 'react';

import { Button } from './ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from './ui/dialog.tsx';

import { ZapIcon, CheckIcon } from 'lucide-react';

const Features = [
    'Analyze your form performance',
    'See Completed vs Partially Completed replies',
    'View unique views and traffic sources',
    'Analyze browser and device usage',
    'View location of your users',
    'Export your contacts',
];

type ProModalProps = {
    isOpen: boolean;
    onContinue: () => void;
    onClose: () => void;
};

export function ProModal({ isOpen, onContinue, onClose }: ProModalProps) {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent
                className="p-0 gap-0 top-[50%] w-[814px] max-w-5xl rounded-[16px] [&>button]:hidden border-none outline-none"
                onInteractOutside={(e) => {
                    e.preventDefault();
                    onClose();
                }}
            >
                <div className="grid md:grid-cols-2">
                    <div className="p-0 m-0 rounded-l-[7px] rounded-r-none bg-gradient-to-b from-[#53E0FF] via-[#AB6BFF] to-[#FF57EE]">
                        <img
                            src="/analytics-dashboard-ui.png"
                            alt="Analytics Dashboard UI"
                            className="w-full h-full object-cover rounded-bl-[6px]"
                            width={407}
                        />
                    </div>

                    <div className="p-8 flex flex-col items-center justify-center">
                        <DialogHeader>
                            <DialogTitle className="text-xl">
                                Buildform Analytics for powerful insights &
                                tracking
                            </DialogTitle>
                            <DialogDescription className="text-sm text-gray-700">
                                Gain valuable insights into your form
                                performance, track user engagement, analyze
                                response patterns, and make data-driven
                                decisions.
                            </DialogDescription>
                        </DialogHeader>

                        <div className="mt-8 w-full">
                            <h3 className="font-semibold text-sm">
                                What&apos;s included:
                            </h3>
                            <ul className="mt-[14px] space-y-4">
                                {Features.map((feature) => (
                                    <li
                                        key={feature}
                                        className="flex items-center gap-[6px]"
                                    >
                                        <div className="bg-[#5DC38E] rounded-full flex flex-col items-center justify-center w-4 h-4">
                                            <CheckIcon className="w-3 h-3 text-white" />
                                        </div>
                                        <span className="text-gray-700 text-sm">
                                            {feature}
                                        </span>
                                    </li>
                                ))}
                            </ul>

                            <Button
                                className="h-[48px] w-full mt-[49px]"
                                onClick={onContinue}
                            >
                                <span className="mr-1">
                                    <ZapIcon className="w-4 h-4" />
                                </span>
                                Buildform Pro
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

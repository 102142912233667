import { Typography, Flex, Col, Row, Grid } from 'antd';
import Center from '../Basic/Center';
import React from 'react';
import { MaxHeight } from '../Basic/MaxHeight';
import { MarkdownTypography } from './MarkdownTypography';
import { motion } from 'framer-motion';
import { FormHeader } from './FormHeader';
import { useFormContext } from '../../Contexts/FormContext';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { AttachmentLayout } from './AttachmentLayout';
import {
    getDynamicTextTitle,
    getDynamicTextDescription,
} from '../../Constants/styles';
import { FormNextButton } from './FormNextButton';
import clsx from 'clsx';
import { useNext } from './LoadingScreen.jsx';

const { useBreakpoint } = Grid;

export const WelcomeScreen = () => {
    const onNext = useNext();
    const { data, formTheme } = useFormContext();

    const constTheme = formTheme;
    const welcome_screen = data?.welcome_screens?.[0];

    if (!welcome_screen) {
        return null;
    }

    const dynamicTextTitle = getDynamicTextTitle(constTheme);
    const dynamicTextDescription = getDynamicTextDescription(constTheme);
    // useEnterPress(next);

    return (
        <MaxHeight>
            <KeyboardEventHandler
                handleKeys={['enter']}
                onKeyEvent={(key, e) => {
                    onNext();
                }}
            />
            <FormHeader />
            <Center align={welcome_screen?.properties?.align || 'start'}>
                <WelcomeScreenContainer
                    title={
                        <Typography.Title
                            level={2}
                            style={{
                                color: constTheme?.text_color,
                                fontSize: dynamicTextTitle,
                            }}
                        >
                            {welcome_screen.title}
                        </Typography.Title>
                    }
                    description={
                        <Typography.Text
                            style={{
                                fontSize: dynamicTextDescription,
                                textAlign: 'left',
                                color: constTheme?.text_color,
                                marginBottom: '0.5em',
                                marginTop: '0.5em',
                                fontWeight: 'normal',
                            }}
                        >
                            <MarkdownTypography>
                                {welcome_screen?.properties?.description}
                            </MarkdownTypography>
                        </Typography.Text>
                    }
                    href={welcome_screen?.attachment?.href}
                    align={
                        welcome_screen?.properties?.align
                            ? welcome_screen?.properties?.align
                            : 'start'
                    }
                    show_button={welcome_screen?.properties?.show_button}
                    next={onNext}
                    button_text={welcome_screen?.properties?.button_text}
                    layout={welcome_screen?.layout}
                    constTheme={constTheme}
                />
            </Center>
        </MaxHeight>
    );
};

export const WelcomeScreenContainer = ({
    title,
    description,
    next = () => {},
    layout = {},
    button_text,
}) => {
    //const hasAttachment = !!layout;
    const hasAttachment = layout?.attachment?.href || layout?.attachment?.link;

    const attachmentPlacement = (hasAttachment && layout?.placement) || 'right';

    const { md: s } = useBreakpoint();

    const dynamicWidth = '100vw';

    return (
        <>
            <div style={{}} className="hidden w-full lg:block">
                <Row style={{ width: dynamicWidth }}>
                    {hasAttachment && attachmentPlacement === 'left' && (
                        <Col
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            lg={24}
                            md={24}
                            sm={24}
                        >
                            <AttachmentLayout attachment={layout.attachment} />
                        </Col>
                    )}
                    <Col
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Flex
                            style={{
                                width: '70%',
                                alignItems: 'start',
                            }}
                            vertical
                            component={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <motion.div
                                initial={{ opacity: 0, translateY: 20 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 0, duration: 1.0 }}
                                className="flex flex-col w-full "
                            >
                                {title}

                                {/*  <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="w-full "
              > */}
                                {description}
                                {/*       </motion.div> */}
                                {/*   <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ delay: 1, duration: 0.5 }}
              > */}
                                {/* <Button size="large" onClick={next}>
                start
              </Button> */}
                                <div>
                                    <FormNextButton onClick={next}>
                                        {button_text}
                                    </FormNextButton>
                                </div>
                                {/* </motion.div> */}
                            </motion.div>
                        </Flex>
                    </Col>
                    {hasAttachment && attachmentPlacement === 'right' && (
                        <Col
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            lg={24}
                            md={24}
                            sm={24}
                        >
                            <AttachmentLayout attachment={layout.attachment} />
                        </Col>
                    )}
                </Row>
            </div>
            <div className="flex flex-col justify-center w-full px-4 lg:hidden ">
                {hasAttachment && attachmentPlacement === 'left' && (
                    <div className="flex justify-center -mb-4">
                        <AttachmentLayout attachment={layout?.attachment} />
                    </div>
                )}

                <div
                    className={`flex flex-col justify-center ${
                        attachmentPlacement === 'left' ? 'pb-12' : ''
                    } gap-[15px] items-center`}
                >
                    <motion.div
                        initial={{ opacity: 0, translateY: 40 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ delay: 0, duration: 0.5 }}
                        className={clsx(
                            ' text-[30px] font-bold',
                            hasAttachment ? 'mt-32' : 'mt-0',
                        )}
                    >
                        {title}

                        {description}
                    </motion.div>
                </div>
                {hasAttachment && attachmentPlacement === 'right' && (
                    <div className="flex justify-center py-4 mb-4">
                        <AttachmentLayout
                            attachment={layout.attachment}
                            mobile={true}
                        />
                    </div>
                )}
                <motion.div
                    initial={{ opacity: 0, translateY: 40 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    transition={{ delay: 1, duration: 0.5 }}
                    className=" fixed left-0 border-t-1 border-t-gray-600 right-0 bottom-0 lg:hidden flex mt-4 py-4 justify-center bg-[#0000000c] w-full"
                >
                    {/* <Button size="large" onClick={next}>
                start
              </Button> */}
                    <FormNextButton onClick={next}>
                        {button_text}
                    </FormNextButton>
                </motion.div>
            </div>
        </>
    );
};

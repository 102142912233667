import { motion } from "framer-motion";

export const AttachmentLayout = ({ attachment }) => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const style = width > height ? { width: "80%" } : { height: "80%" };

    return (
        <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, ease: "easeOut" }}
            src={attachment?.href || attachment?.link}
            style={{ ...style, objectFit: "contain", borderRadius: "18px" }}
        />
    );
};

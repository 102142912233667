import { createContext, useState, useContext, useEffect } from 'react';
import { SETTING_CONTEXT } from '../Constants/questionTypes.js';
import { updateForm } from '../Redux/slices/formSlice.tsx';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../Api/useApi.ts';
import { message } from 'antd';
const FormEditorContext = createContext();
import {
    convertFormObject,
    convertTOThemeObject,
} from '../Components/MyEditor/FormObjectConfig.jsx';
import { useFormContext } from './FormContext.jsx';
import isEqual from 'lodash/isEqual';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useLocation } from 'react-router-dom';

// Enum for save states

export const FormEditorProvider = ({ children }) => {
    const SaveStatus = {
        UNSAVED: 'UNSAVED',
        SAVING: 'SAVING',
        SAVED: 'SAVED',
    };
    const dispatch = useDispatch();
    const location = useLocation();

    const [form, setForm] = useState({}); //used to save global form details
    const [formStatus, setFormStatus] = useState('');
    const [changed, setChanged] = useState(false);
    const [themeChanged, setThemeChanged] = useState(false);
    const [fields, setFields] = useState([]); //used to store data about fields
    const [welcomeScreen, setWelcomeScreen] = useState({}); //used to store data about welcome screen
    const [selectedField, _setSelectedField] = useState('');
    const [settingsContext, setSettingsContext] = useState(
        SETTING_CONTEXT?.FIELD,
    );
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [editorField, setEditorField] = useState(null);
    const [endScreen, setEndScreen] = useState({});
    const [selectedWorkSpace, setSelectedWorkSpace] = useState(
        sessionStorage.getItem('selectedWorkSpace') || '',
    );
    const [isPublished, setIsPublished] = useState(false);
    const [theme, setTheme] = useState({});
    const [name, setName] = useState(null);
    const [isPreview, setIsPreview] = useState(false);
    const { data, setData } = useFormContext();
    const [isWelcomeIsEnabled, setIsWelcomeIsEnabled] = useState(false);
    const [isEndIsEnabled, setIsEndIsEnabled] = useState(false);
    const [saveStatus, setSaveStatus] = useState(SaveStatus.SAVED);
    const [hasChanges, setHasChanges] = useState(false);
    const [formSettings, setFormSettings] = useState({});
    const isEditor = location.pathname.includes('form-editor');
    const [device, setDevice] = useState('desktop');
    const [isFormReady, setIsFormReady] = useState(false);
    const [showPublishedModal, setShowPublishedModal] = useState(false);


    useEffect(() => {
        setData({ ...data, fields: fields });
    }, [fields]);

    const setSelectedField = (field) => {
        setEditorField(field);
        _setSelectedField(field);
    };

    /* invalidate query if the router path changed */
    const queryClient = useQueryClient();
    const { id } = useParams();

    useEffect(() => {
        queryClient.invalidateQueries(['form', id]);
    }, [id, location.pathname]); // Invalidate when the path changes

useEffect(() => {
        if (form) {
            setFields(form?.fields || []);
            setWelcomeScreen(form?.welcomeScreen || {});
            setEndScreen(form?.endScreen || {});
            setIsPublished(form?.url_id ? true : false);
            setTheme(form?.theme || {});
            setName(form?.name || form?.title || '');
            setFormSettings(form?.settings || {});
            if (
                form?.welcomeScreen &&
                Object.keys(form?.welcomeScreen).length > 0
            ) {
                setIsWelcomeIsEnabled(true);
                setSelectedField('welcome-screen');
            } else {
                if (form?.fields && form?.fields?.length > 0) {
                    setSelectedField(form?.fields?.[0]);
                }
            }

            if (form?.endScreen && Object.keys(form?.endScreen).length > 0) {
                setIsEndIsEnabled(true);
            }
            setIsFormReady(true);
        }
    }, [form]);

    const addField = (field) => {
        let updatedFields = [...fields];

        if (selectedField === 'welcome-screen') {
            // Insert at the beginning (index 0)
            updatedFields.splice(0, 0, field);

            // Update question numbers for subsequent fields
            updatedFields = updatedFields.map((f, index) => ({
                ...f,
                question_number: index > 0 ? String(index) : '',
            }));
        } else if (selectedField === 'end-screen') {
            // Add to the end
            updatedFields.push(field);
        } else {
            // Find the index of the selected field
            const selectedIndex = fields.findIndex(
                (f) => f.id === selectedField.id,
            );
            // Insert after the selected field
            updatedFields.splice(selectedIndex + 1, 0, field);

            // Update question numbers for subsequent fields
            updatedFields = updatedFields.map((f, index) => ({
                ...f,
                question_number: String(index + 1),
            }));
        }

        setFields(updatedFields);
        dispatch(updateForm({ ...form, fields: updatedFields }));
        setChanged(true);
    };

    useEffect(() => {
        if (saveStatus === SaveStatus.UNSAVED) {
            const timeoutId = setTimeout(() => {
                const updatedForm = {
                    ...form,
                    fields,
                    welcomeScreen,
                    endScreen,
                    name,
                };
                dispatch(updateForm(updatedForm));
                saveChanges();
            }, 0);

            // Cleanup the timeout if `changed` becomes false or the component unmounts
            return () => clearTimeout(timeoutId);
        }
    }, [saveStatus]);

    /* form update endpoint handling  */
    const [messageApi, contextHolder] = message.useMessage();
    const api = useApi();

    const updateMutation = useMutation({
        mutationFn: ({ form, workspaceId, theme, formId, is_published }) => {
            if (!formId) {
                return console.error('Form ID is missing');
            }
            if (!workspaceId) {
                return console.error('Workspace ID is missing');
            }
            if (!isFormReady) {
                return console.error('Form is not ready');
            }
            return api.revisions.createRevision(
                form,
                workspaceId,
                theme,
                formId,
                is_published,
            );
        },
        //api.forms.updateForm(url_id, form),
        retry: (failureCount, error) => {
            // Check if the error is a network error
            if (!error.response) {
                setSaveStatus(SaveStatus.UNSAVED);
                return true; // Retry for network errors
            }
            // Handle specific status codes
            if (error.response.status === 404) {
                setSaveStatus(SaveStatus.UNSAVED);
                return false; // Don't retry for 404
            }
            return failureCount < 3; // Retry up to 3 times
        },
        retryDelay: (attemptIndex) => {
            const delay = Math.min(20000 * 2 ** attemptIndex, 30000); // Max 30 seconds
            // Set the save status to UNSAVED during the delay
            setTimeout(() => {
                setSaveStatus(SaveStatus.UNSAVED);
            }, delay);

            return delay;
        },
        onSuccess: (data) => {
            setSaveStatus(SaveStatus.SAVED);
            if (data?.data?.status === 'published') {
                setFormStatus('published');
                sessionStorage.setItem('formStatus', 'published');
                setShowPublishedModal(true);
            }
            setChanged(false);
        },
        onError: (error) => {
            console.error('Error details:', error);
            if (!error.response) {
                setSaveStatus(SaveStatus.UNSAVED);
            } else {
                // Handle other types of errors as needed
                setSaveStatus(SaveStatus.UNSAVED);
            }
        },
    });

    useEffect(() => {
        // Compare current form state with original to determine if changes exist
        const originalForm = {
            ...form,
            fields: form?.fields,
            welcomeScreen: form?.welcomeScreen,
            endScreen: form?.endScreen,
            name: form?.name,
            theme: form?.theme,
            settings: form?.settings,
        };

        const currentForm = {
            ...form,
            fields,
            welcomeScreen,
            endScreen,
            name,
            theme,
            settings: formSettings,
        };

        /*   const hasFormChanged =
      JSON.stringify(originalForm) !== JSON.stringify(currentForm); */
        if (!originalForm?.name || !currentForm?.name) {
            return;
        }
        const hasFormChanged = !isEqual(originalForm, currentForm);

        setHasChanges(hasFormChanged);

        // Update save status based on changes
        if (hasFormChanged) {
            setSaveStatus(SaveStatus.UNSAVED);
        }
    }, [fields, welcomeScreen, endScreen, name, form, theme, formSettings]);

    // Save changes method
    const saveChanges = ({ isPublished = false } = {}) => {
        if (!hasChanges && !isPublished) {
            return;
        }

        setSaveStatus(SaveStatus.SAVING);

        // Validate that required data is not empty
        if (!name || !formSettings) {
            return;
        }

        const updatedForm = {
            ...form,
            fields,
            welcomeScreen,
            endScreen,
            name,
            settings: formSettings,
        };
        dispatch(updateForm(updatedForm));

        // Convert form and theme objects to the required format
        const newObjForm = convertFormObject(updatedForm);
        const newObjTheme = convertTOThemeObject(theme);

        // Get the workspace ID from the session storage
        const workspaceId = sessionStorage.getItem('selectedWorkSpace');
        const form_id = updatedForm?.id;

        // Call the mutation to save the changes
        updateMutation.mutate({
            form: newObjForm,
            workspaceId: workspaceId,
            theme: newObjTheme,
            formId: form_id,
            is_published: isPublished,
        });
    };

    const goToNext = () => {
        if (selectedField === 'welcome-screen') {
            return fields?.length === 0
                ? isEndIsEnabled
                    ? setSelectedField('end-screen')
                    : setSelectedField('welcome-screen')
                : setSelectedField(fields[0]);
        }

        const index = fields.findIndex(
            (field) => field.id === selectedField?.id,
        );
        if (index === fields.length - 1) {
            setSelectedField('end-screen');
        } else {
            setSelectedField(fields[index + 1]);
        }

        if (selectedField === 'end-screen')
            return setSelectedField('welcome-screen');
    };

    return (
        <>
            {contextHolder}
            <FormEditorContext.Provider
                value={{
                    form,
                    setForm,
                    welcomeScreen,
                    setWelcomeScreen,
                    fields,
                    setFields,
                    addField,
                    selectedField,
                    setSelectedField,
                    settingsContext,
                    setSettingsContext,
                    saveChanges,
                    editorField,
                    setEditorField,
                    endScreen,
                    setEndScreen,
                    selectedWorkSpace,
                    setSelectedWorkSpace,
                    theme,
                    setTheme,
                    name,
                    setName,
                    changed,
                    setChanged,
                    themeChanged,
                    setThemeChanged,
                    isPreview,
                    setIsPreview,
                    isSidebarOpen,
                    setIsSidebarOpen,
                    isWelcomeIsEnabled,
                    setIsWelcomeIsEnabled,
                    saveStatus,
                    goToNext,
                    isEndIsEnabled,
                    setIsEndIsEnabled,
                    formSettings,
                    setFormSettings,
                    device,
                    setDevice,
                    formStatus,
                    setFormStatus,
                    showPublishedModal,
                    setShowPublishedModal,
                }}
            >
                {children}
            </FormEditorContext.Provider>
        </>
    );
};

export const useFormEditorContext = () => {
    const context = useContext(FormEditorContext);
    if (!context) {
        throw new Error(
            'useFormEditorContext must be used within a FormProvider',
        );
    }
    return context;
};
